import React, { Fragment, Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import { reset } from 'redux-form'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import PublishIcon from '@material-ui/icons/Publish'
import DialogTitle from '@material-ui/core/DialogTitle'
import { refreshView as refreshViewAction } from 'ra-core'
import { showNotification } from 'react-admin'
import { connect } from 'react-redux'
import { translate, Button, SimpleForm, FileInput, FileField, ReferenceInput, SelectInput, required } from 'react-admin'
import { importBlacklist } from '../../../../api'

class ImportButton extends Component {
  state = {
    isOpen: false,
  }

  convertFileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file.rawFile)
      reader.onload = () => resolve(reader.result)
      reader.onerror = reject
    })

  handleClick = () => {
    this.setState({ isOpen: true })
  }

  handleDialogClose = () => {
    this.setState({ isOpen: false })
    this.props.resetForm()
  }

  handleConfirm = (form) => {
    const { clientId, files, module_type} = form
    this.convertFileToBase64(files).then((response) => {
      const { showNotification } = this.props
      importBlacklist({ moduleId: module_type.id, slug: clientId, file: response })
        .then(() => {
          showNotification(this.props.translate(`resources.blacklists.notifications.importSuccess`))
          this.props.refreshView()
        })
        .catch(() => {})
        .finally(() => this.handleDialogClose())
      this.setState({ isOpen: true })
    })
  }

  render() {
    return (
      <Fragment>
        <Button label={this.props.translate(`resources.blacklists.import`)} onClick={this.handleClick}>
          <PublishIcon />
        </Button>
        <Dialog open={this.state.isOpen} onClose={this.handleDialogClose} title={this.props.translate(`resources.blacklists.import`)}>
          <SimpleForm save={this.handleConfirm}>
            <DialogTitle>{this.props.translate(`resources.blacklists.import`)}</DialogTitle>
            <ReferenceInput
              fullWidth
              validate={required()}
              label={this.props.translate(`resources.blacklists.modal.client`)}
              source="clientId"
              reference="clients"
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              fullWidth
              validate={required()}
              label="module"
              source="module_type.id"
              reference="modules"
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <FileInput
              validate={required()}
              source="files"
              label={this.props.translate(`resources.blacklists.modal.document`)}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            >
              <FileField source="src" title="title" />
            </FileInput>
          </SimpleForm>
        </Dialog>
      </Fragment>
    )
  }
}

ImportButton.propTypes = {
  showNotification: PropTypes.func.isRequired,
  refreshView: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
}

ImportButton.defaultProps = {}

const mapDispatchToProps = (dispatch) => {
  return {
    resetForm: () => dispatch(reset('record-form')),
    showNotification: (text) => dispatch(showNotification(text)),
    refreshView: () => dispatch(refreshViewAction()),
  }
}

const enhance = compose(translate, connect(undefined, mapDispatchToProps))

export default enhance(ImportButton)
