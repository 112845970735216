import React from 'react'
import { Show, TabbedShowLayout, NumberField, ReferenceField, TextField, Tab } from 'react-admin'

const AvailabilitysTagShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <NumberField source="position" />
        <TextField source="label" />
        <ReferenceField source="client" reference="clients">
          <TextField source="name" />
        </ReferenceField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default AvailabilitysTagShow
