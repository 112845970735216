import React from 'react'
import {List, Datagrid, TextField, ReferenceField, Filter, TextInput} from 'react-admin'

const ProcessesFilter = props => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="name" />
    <TextInput source="client.id" />
    <TextInput source="client.name" />
    <TextInput source="remote_name" />
  </Filter>
)

const ProcessesList = props => (
  <List {...props} exporter={false} filters={<ProcessesFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="remote_name" />
      <ReferenceField source="client.id" reference="clients" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default ProcessesList
