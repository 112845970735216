import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as api from '../../../api'

const AvatarField = ({ record = {}, resource = '' }) => {
  const [image, setImage] = useState(null)

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const result = await api.getAvatar({ resource, slug: record.id })
        setImage(result.url)
      } catch (error) {
        setImage(null)
      }
    }
    fetchImage()
  }, [record, resource])

  const onError = () => {
    setImage(null)
  }

  const onClick = e => {
    e.preventDefault()
    window.open(image)
  }

  if (!image) return null

  return <img style={{ width: 250, cursor: 'pointer' }} src={image} alt="avatar" onClick={onClick} onError={onError} />
}

AvatarField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string
}

AvatarField.defaultProps = {
  addLabel: true
}

export default AvatarField
