import Image from '../../../assets/images/login_background.png'

export default {
  main: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: `url(${Image}) no-repeat center center fixed`,
    backgroundSize: 'cover',
    overflow: 'hidden',
    backgroundColor: '#dddddd'
  },
  avatar: {},
  icon: {}
}
