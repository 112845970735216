export default {
  black: '#000000',
  blackAlpha80: 'rgba(0, 0, 0, 0.8)',
  blackAlpha50: 'rgba(0, 0, 0, 0.5)',
  blackAlpha20: 'rgba(0, 0, 0, 0.2)',
  blackAlpha10: 'rgba(0, 0, 0, 0.1)',
  white: '#ffffff',
  whiteAlpha50: 'rgba(255, 255, 255, 0.5)',
  blue: '#003e83',
  grey: '#C4CCD4',
  greyAlpha25: 'rgba(148, 163, 178, 0.25)',
  greyAlpha15: 'rgba(148, 163, 178, 0.15)',
  yellow: '#DCCD47',
  red: '#FB344C',
  green: '#5DCA62',
  orange: '#E0A93F',
  gold: '#ead1a2'
}
