import React from 'react'
import { List, Datagrid, ReferenceField, TextField, Filter, TextInput, ReferenceInput, SelectInput, AutocompleteInput } from 'react-admin'

const ReportFieldsFilter = props => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="name" />
    <ReferenceInput source="client.id" reference="clients" allowEmpty label="Cliente"
                    sort={{field: 'name', order: 'ASC'}} perPage={1000}
                    filterToQuery={(searchText) => ({ name: searchText})}
    >
      <AutocompleteInput source="name"/>
    </ReferenceInput>
    <ReferenceInput source="module_type.id" reference="modules" allowEmpty label="module">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="remote_name" />
  </Filter>
)

const ReportFieldsList = props => (
  <List {...props} exporter={false} filters={<ReportFieldsFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="remote_name" />
      <ReferenceField source="client.id" reference="clients" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default ReportFieldsList
