import React from 'react'
import { List, Datagrid, TextField, BooleanField, BooleanInput, ReferenceField, ChipField, Filter, TextInput } from 'react-admin'

const AnswersSkillsFilter = props => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="name" />
    <TextInput source="value" />
    <BooleanInput source="punctuate" />
  </Filter>
)

const AnswersSkillsList = props => (
  <List {...props} exporter={false} filters={<AnswersSkillsFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="value" />
      <BooleanField source="punctuate" />
      <ReferenceField source="skill.id" reference="skills" linkType={false}>
        <ChipField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default AnswersSkillsList
