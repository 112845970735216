import React from 'react'
import { BooleanField, Datagrid, EmailField, Filter, List, TextField, TextInput, ShowButton } from 'react-admin'
import { MODULE_IDS } from '../../../../config/api'
import { DeleteUsersField } from '../../../molecules'

const ClientsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="name" />
  </Filter>
)

const ClientsList = (props) => (
  <List {...props} exporter={false} filters={<ClientsFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <EmailField source="email" />
      <DeleteUsersField moduleId="talent_acquisition" moduleName="Candidaturas" label="Borrar datos de módulo de Candidaturas" />
      <BooleanField source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.enabled'} />
      <DeleteUsersField moduleId="employee_line" moduleName="Línea de empleado" label="Borrar datos de módulo de Línea de empleado" />
      <BooleanField source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.enabled'} />
      <DeleteUsersField moduleId="on_board" moduleName="Onboarding" label="Borrar datos de módulo de Onboarding" />
      <BooleanField source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.enabled'} />
      <BooleanField source={'module_' + MODULE_IDS.ID_FEEDBACK + '.enabled'} />
      <BooleanField source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.enabled'} />
      <ShowButton />
    </Datagrid>
  </List>
)

export default ClientsList
