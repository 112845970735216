import React from 'react'
import {
  BooleanField,
  Datagrid,
  EmailField,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  TextField,
  TextInput
} from 'react-admin'

const Filters = props => {
  return <Filter {...props}>
    <TextInput source="id"/>
    <TextInput source="name"/>
    <TextInput source="email"/>
    <ReferenceInput source="client.id" reference="clients" allowEmpty label="Cliente"
                    sort={{field: 'name', order: 'ASC'}} perPage={1000}
                    filterToQuery={(searchText) => ({ name: searchText})}
    >
      <AutocompleteInput source="name"/>
    </ReferenceInput>
  </Filter>
}

const UserList = props => (
  <List {...props} exporter={false} filters={<Filters />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="surname" />
      <TextField source="phone" />

      <BooleanField source="technical_selection_user.enabled" />
      <BooleanField source="rrhh_user.enabled" />
      <BooleanField source="user_feedback.enabled" />
      <BooleanField source="user_exit_interview.enabled" />
      <BooleanField source="admin_user.enabled" />

      <EmailField source="email" />
      <ReferenceField source="client.id" reference="clients" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default UserList
