import React, { cloneElement, Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'
import MenuItem from '@material-ui/core/MenuItem'
import { withStyles, createStyles } from '@material-ui/core/styles'

const styles = theme =>
  createStyles({
    root: {
      color: theme.colors.whiteAlpha50,
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: 7
    },
    active: {
      color: theme.palette.primary.contrastText,
      background: theme.colors.blackAlpha20
    },
    icon: { paddingRight: '1.2em' }
  })

export class MenuItemLink extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    leftIcon: PropTypes.node,
    rightIcon: PropTypes.node,
    onClick: PropTypes.func,
    primaryText: PropTypes.node,
    staticContext: PropTypes.object,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
  }

  handleMenuTap = e => {
    this.props.onClick && this.props.onClick(e)
  }

  render() {
    const {
      classes,
      className,
      primaryText,
      leftIcon,
      rightIcon,
      // eslint-disable-next-line no-unused-vars
      staticContext,
      ...props
    } = this.props

    const inactiveColor = 'rgba(0, 0, 0, 0.54)'
    return (
      <MenuItem className={classnames(classes.root, className)} activeClassName={classes.active} component={NavLink} {...props} onClick={this.handleMenuTap}>
        {leftIcon && (
          <span className={classes.icon} style={rightIcon ? { color: inactiveColor } : {}}>
            {cloneElement(leftIcon, { titleAccess: primaryText })}
          </span>
        )}
        <span style={rightIcon ? { display: 'flex', flex: 1, color: inactiveColor } : {}}>{primaryText}</span>
        {rightIcon && (
          <span className={classes.icon} style={rightIcon ? { color: inactiveColor } : {}}>
            {cloneElement(rightIcon, { titleAccess: primaryText })}
          </span>
        )}
      </MenuItem>
    )
  }
}

export default withStyles(styles)(MenuItemLink)
