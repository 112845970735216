import React from 'react'
import {Create, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput, AutocompleteInput} from 'react-admin'
import {ColorInput} from "react-admin-color-input";

const ReminderConfigsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <ReferenceInput source="client.id" reference="clients" allowEmpty label="Cliente"
                      sort={{field: 'name', order: 'ASC'}} perPage={1000}
                      filterToQuery={(searchText) => ({ name: searchText})}
        >
          <AutocompleteInput source="name"/>          
        </ReferenceInput>
        <ReferenceInput source="module_type.id" reference="modules" perPage={100} label="module" validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" validate={required()} />
        <TextInput source="description" validate={required()} />
        <ColorInput source="color" />
        <NumberInput source="order" validate={required()} />
      </SimpleForm>
    </Create>
  )
}

export default ReminderConfigsCreate
