import { createHashHistory } from 'history'
import { resolveBrowserLocale } from 'react-admin'
import { Login } from '../components/pages'
import AppLayout from '../components/system/layout'
import { theme, api } from './'
import { authProvider, dataProvider, i18nProvider } from '../providers'
import addCustomFeatures from '../providers/dataProvider/addCustomFeatures'
import addUploadFeature from '../providers/dataProvider/addUploadFeature'

const locale = resolveBrowserLocale()

const history = createHashHistory()

const appDataProvider = dataProvider(api.BASE_URL + api.API_ENTRYPOINT)
const addCustomFeaturesDataProvider = addUploadFeature(addCustomFeatures(appDataProvider))

export default {
  authProvider,
  dataProvider: addCustomFeaturesDataProvider,
  i18nProvider,
  locale,
  theme,
  loginPage: Login,
  appLayout: AppLayout,
  history
}
