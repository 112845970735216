import React from 'react'
import { Login } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

const CustomLoginPage = props => <Login {...props} backgroundImage={null} />

const enharce = withStyles(styles)

export default enharce(CustomLoginPage)
