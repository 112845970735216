import { CREATE, UPDATE } from 'react-admin'

const addCustomFeatures = requestHandler => (type, resource, params) => {
  // for other request types and resources, fall back to the default request handler

  if (resource === 'technical_selections' && (type === CREATE || type === UPDATE)) {
    const newClient = { id: params.data.client }
    const newParams = { ...params, data: { ...params.data, client: newClient } }
    return requestHandler(type, resource, newParams)
  } else if (resource === 'default_messages' && (type === CREATE || type === UPDATE)) {
    const newClient = { id: params.data.client }
    const newParams = { ...params, data: { ...params.data, client: newClient } }
    return requestHandler(type, resource, newParams)
  }

  return requestHandler(type, resource, params)
}

export default addCustomFeatures
