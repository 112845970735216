import React from 'react'
import {
  ReferenceField,
  TextField,
  NumberField,
  Show,
  ReferenceArrayField,
  ChipField,
  TabbedShowLayout,
  Tab,
  Datagrid
} from 'react-admin'

const CandidaturesShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="remote_name" />

        <ReferenceField source="client" reference="clients" allowEmpty>
          <TextField source="name" />
        </ReferenceField>

        <NumberField source="minimum_percentage_suitable_overwrite" />
        <NumberField source="skill_weight_overwrite"/>
        <NumberField source="profile_weight_overwrite"/>
      </Tab>

      <Tab label="skills">
        <ReferenceField source="skill1" reference="skills">
          <ChipField source="name" />
        </ReferenceField>
        <NumberField source="weight_skill1" />
        <ReferenceField source="skill2" reference="skills">
          <ChipField source="name" />
        </ReferenceField>
        <NumberField source="weight_skill2" />
        <ReferenceField source="skill3" reference="skills">
          <ChipField source="name" />
        </ReferenceField>
        <NumberField source="weight_skill3" />
        <ReferenceField source="skill4" reference="skills">
          <ChipField source="name" />
        </ReferenceField>
        <NumberField source="weight_skill4" />
      </Tab>

      <Tab label="questions">
        <ReferenceArrayField source="questions" reference="questions" addLabel={false}>
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="internal_name" />
            <TextField source="weight" />
          </Datagrid>
        </ReferenceArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default CandidaturesShow
