import styled from 'styled-components'

export const FilterContainer = styled.div`
 display: inline-flex;
    gap: 10px;
`

export const FilterItem = styled.div`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`
export const InputFilter = styled.input`
    margin-right: 10px;
    height: 24px !important;
    width: 240px;
`

export const ButtonFilter = styled.button`
    padding-inline: 20px;
`



export const TableWrapper = styled.div`
    .rdt_TableCol {
      width: 240px;
      background-color: #5a8cc4;
    }
    .rdt_TableCol[data-column-id="1"]{
      width: 300px;
      min-width: 300px;
      background-color: #3166a1;
    }
    
    .rdt_TableCol[data-column-id="2"] {
      width: 160px;
     background-color: #3166a1;
    }
    
    .rdt_TableCol[data-column-id="3"] {
      max-width: 40px;
    }
`

