import React, {useState} from 'react'
import {
  AutocompleteArrayInput,
  Create,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'
import {MODULE_IDS, PROCESS_TYPES} from "../../../../config/api";

const ProcessesCreate = props => {
  const [count, setCount] = useState(0)
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} />
        <TextInput source="prefix" inputProps={{ maxLength: 3 }} validate={[required()]} />

        <ReferenceInput source="client.id" reference="clients" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput source="type.id" reference="processtypes" label="processType">
          <SelectInput optionText="name" />
        </ReferenceInput>

        <FormDataConsumer>
          {// eslint-disable-next-line no-unused-vars
            ({ formData, dispatch, ...rest }) => (
              formData.type && formData.type.id === PROCESS_TYPES.ID_TRANSACCIONAL &&
              <ReferenceArrayInput
                {...rest}
                key={`questions_${count}`}
                reference="questions"
                source="questions"
                filter={{"moduleType.id": MODULE_IDS.ID_EMPLOYEE_LINE}}
                perPage={1000}
                filterToQuery={searchText => ({ internal_name: searchText, "moduleType.id": MODULE_IDS.ID_EMPLOYEE_LINE  })}
                onChange={() => setTimeout(() => setCount(count + 1), 250)}
                allowEmpty
              >
                <AutocompleteArrayInput optionText="internal_name" shouldRenderSuggestions={() => true} inputValueMatcher={() => true} />
              </ReferenceArrayInput>
            )}
        </FormDataConsumer>



      </SimpleForm>
    </Create>
  )
}

export default ProcessesCreate
