import React from 'react'
import { List, Datagrid, TextField, NumberField, ReferenceField } from 'react-admin'

const AvailabilityTagsList = props => (
  <List {...props} exporter={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="label" />
      <ReferenceField source="client.id" reference="clients">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="position" />
    </Datagrid>
  </List>
)

export default AvailabilityTagsList