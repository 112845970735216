import React from 'react'
import {BooleanField, EmailField, ReferenceField, Show, Tab, TabbedShowLayout, TextField} from 'react-admin'
import {AvatarField} from '../../../atoms'

const UserShow = props => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="surname" />
          <TextField source="phone" />
          <EmailField source="email" />
          <ReferenceField source="client.id" reference="clients" allowEmpty>
            <TextField source="name" />
          </ReferenceField>
          <AvatarField source="avatar" />

          <Separator/>

          <BooleanField source="technical_selection_user.enabled"/>
          <TextField source="technical_selection_user.role" />
          <Separator/>

          <BooleanField source="rrhh_user.enabled"/>
          <TextField source="rrhh_user.role" />
          <Separator/>

          <BooleanField source="user_on_boarding.enabled"/>
          <TextField source="user_on_boarding.role" />
          <Separator/>

          <BooleanField source="user_feedback.enabled"/>
          <TextField source="user_feedback.role" />
          <Separator/>

          <BooleanField source="user_exit_interview.enabled"/>
          <TextField source="user_exit_interview.role" />
          <Separator/>

          <BooleanField source="admin_user.enabled"/>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

const Separator = () => <div style={{ height: 5, borderTop: '1px solid #ccc' }} />

export default UserShow
