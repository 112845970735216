import React, { useState } from 'react'
import {Edit, SimpleForm, required, CardActions, ShowButton, translate} from 'react-admin'
import { TextInput, NumberInput, ReferenceInput, SelectInput, ReferenceArrayInput, AutocompleteArrayInput, CloneButton } from 'react-admin'
import {MODULE_IDS} from "../../../../config/api"
import PropTypes from "prop-types";
import compose from 'recompose/compose'

// eslint-disable-next-line
const EditActions = ({ basePath, data, hasShow }) => {
  return (
    <CardActions>
      <CloneButton
        className="button-clone"
        basePath={basePath}
        record={data}
      />
      {hasShow && <ShowButton basePath={basePath} record={data} />}
    </CardActions>
  );
}

const CandidaturesEdit = props => {
  const [count, setCount] = useState(0)
  return (
    <Edit {...props} actions={<EditActions />} undoable={false}>
      <SimpleForm redirect="show">
        <TextInput source="name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} />

        <ReferenceInput source="client" reference="clients" perPage={100}>
          <SelectInput optionText="name" />
        </ReferenceInput>

        <ReferenceInput source="skill1" reference="skills" sort={{ field: 'name', order: 'ASC' }} perPage={1000} validate={[required()]}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="weight_skill1" validate={[required()]} />

        <ReferenceInput source="skill2" reference="skills" sort={{ field: 'name', order: 'ASC' }} perPage={1000} validate={[required()]}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="weight_skill2" validate={[required()]} />

        <ReferenceInput source="skill3" reference="skills" sort={{ field: 'name', order: 'ASC' }} perPage={1000} validate={[required()]}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="weight_skill3" validate={[required()]} />

        <ReferenceInput source="skill4" reference="skills" sort={{ field: 'name', order: 'ASC' }} perPage={1000} validate={[required()]}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="weight_skill4" validate={[required()]} />

        <ReferenceArrayInput
          key={`questions_${count}`}
          reference="questions"
          source="questions"
          filter={{"moduleType.id": MODULE_IDS.ID_TALENT_ACQUISITION}}
          perPage={1000}
          filterToQuery={searchText => ({ internal_name: searchText, "moduleType.id": MODULE_IDS.ID_TALENT_ACQUISITION })}
          onChange={() => setTimeout(() => setCount(count + 1), 250)}
          allowEmpty
        >
          <AutocompleteArrayInput optionText="internal_name" shouldRenderSuggestions={() => true} inputValueMatcher={() => true} />
        </ReferenceArrayInput>

        <NumberInput source="minimum_percentage_suitable_overwrite" />
        <NumberInput source="skill_weight_overwrite"/>
        <NumberInput source="profile_weight_overwrite"/>

        <TextInput
          fullWidth
          multiline
          source='sms_start_bot_template'
          inputProps={{maxLength: 140}}
          helperText={props.translate("resources.clients.fields.module_talent_acquisition.sms_start_bot_template_helper")}
        />

        <TextInput
          fullWidth
          validate={[required()]}
          source='bot_url'
        />

        <TextInput
          fullWidth
          source='schedules_available_to_notify_bot_starter'
          helperText={props.translate("resources.clients.fields.module_talent_acquisition.sms_start_bot_template_helper")}
        />

      </SimpleForm>
    </Edit>
  )
}

CandidaturesEdit.propTypes = {
  translate: PropTypes.func.isRequired,
}

const enhance = compose(translate)

export default enhance(CandidaturesEdit)
