import React from 'react'
import {List, Datagrid, TextField, ReferenceField, Filter, ReferenceInput, SelectInput} from 'react-admin'

const Filters = props => (
  <Filter {...props}>
    <ReferenceInput source="module_type.id" reference="modules" allowEmpty label="module">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const DefaultMessagesList = (props) => (
  <List {...props} exporter={false} filters={<Filters />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="location" />
      <TextField source="interviewer" />
      <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default DefaultMessagesList
