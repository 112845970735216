import React from 'react'
import {List, Datagrid, TextField, Filter, TextInput, SelectInput, ReferenceInput, ReferenceField} from 'react-admin'

const InboxesFilter = props => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="name" />
    <ReferenceInput source="module_type.id" reference="modules" allowEmpty label="module">
      <SelectInput optionText="name" />
    </ReferenceInput>
    <TextInput source="remote_name" />
  </Filter>
)

const InboxesList = props => (
  <List {...props} exporter={false} filters={<InboxesFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="remote_name" />
      <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

export default InboxesList
