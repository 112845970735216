import React from 'react'
import { Show, TabbedShowLayout, Tab } from 'react-admin'
import { Datagrid, ReferenceArrayField, TextField, BooleanField, ReferenceField, ChipField, NumberField } from 'react-admin'
import _ from 'lodash'
import { API_PROFILE_HARD_ID } from '../../../../config/api'

const QuestionsShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name" />
        <TextField source="internal_name" />
        <TextField source="remote_name" />
        <BooleanField source="variable_field" />
        <BooleanField source="is_editable_by_client" />
        <TextField source="order" />

        <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="weight" />
        <ReferenceField source="profile" reference="profiles" linkType={false}>
          <ChipField source="name" />
        </ReferenceField>
      </Tab>
      <Tab label="resources.questions.tabs.answers">
        <AnswersReferenceArrayField source="answers" addLabel={false} />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default QuestionsShow

const AnswersReferenceArrayField = props =>
  _.get(props, 'record.profile') === API_PROFILE_HARD_ID ? (
    <ReferenceArrayField {...props} reference="answers_profile">
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="value" />
        <NumberField source="score" />
      </Datagrid>
    </ReferenceArrayField>
  ) : (
    <ReferenceArrayField {...props} reference="answers_skills">
      <Datagrid rowClick="show">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="value" />
        <BooleanField source="punctuate" />
        <ReferenceField source="skill.id" reference="skills">
          <ChipField source="name" />
        </ReferenceField>
      </Datagrid>
    </ReferenceArrayField>
  )
