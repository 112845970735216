import React from 'react'
import compose from 'recompose/compose'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { AppBar } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import Logo from '../../../assets/images/logo.svg'

const MyAppBar = ({ classes, ...props }) => {
  return (
    <AppBar {...props} className={classes.appbar}>
      <img src={Logo} alt="logo" className={classes.logo} />
      <span className={classes.spacer} />
      <Typography variant="title" color="inherit" className={classes.title} id="react-admin-title" />
      <span className={classes.spacer} />
    </AppBar>
  )
}

MyAppBar.propTypes = {
  formValues: PropTypes.object,
  loading: PropTypes.number,
  classes: PropTypes.object,
  change: PropTypes.func
}

const styles = {
  appbar: {
    boxShadow: '0 0px 0px #000'
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  },
  logo: {
    height: 25,
    margin: '20px 0'
  }
}

const enharce = compose(withStyles(styles))

export default enharce(MyAppBar)
