import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput
} from 'react-admin'

const FiltersBlockCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" label="Nombre" />
        <NumberInput source="order" label="Orden" />
      </SimpleForm>
    </Create>
  )
}

export default FiltersBlockCreate
