import React from 'react'
import { ReferenceInput, SelectInput, required, FormDataConsumer } from 'react-admin'
import PropTypes from 'prop-types'

const MbtisReferenceInput = ({ source, field }) => (
  <FormDataConsumer>
    {({ formData }) => (
      <ReferenceInput
        label="resources.answers_skills.fields.mbti_value"
        source={source}
        resource={source}
        reference="types/mbtis"
        disabled={formData && formData[field] ? false : true}
        filter={{ axis: formData ? [formData[field]] : null }}
        perPage={100}
        validate={[required()]}
      >
        <SelectInput optionText="value" />
      </ReferenceInput>
    )}
  </FormDataConsumer>
)

MbtisReferenceInput.propTypes = {
  field: PropTypes.string,
  source: PropTypes.string
}

export default MbtisReferenceInput
