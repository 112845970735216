import React, { useState } from 'react'
import { TextInput, NumberInput, ReferenceInput, SelectInput, ReferenceArrayInput, BooleanInput } from 'react-admin'
import { Create, SimpleForm, required, FormDataConsumer, AutocompleteArrayInput, REDUX_FORM_NAME } from 'react-admin'
import { change } from 'redux-form'
import { API_PROFILE_HARD_ID, MODULE_IDS } from '../../../../config/api'

const QuestionsCreate = props => {
  const [count, setCount] = useState(0)
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" validate={[required()]} />
        <TextInput source="internal_name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} />
        <BooleanInput source="variable_field" />
        <BooleanInput source="is_editable_by_client" />
        <NumberInput source="order" />

        <FormDataConsumer>
          {({ dispatch, ...rest }) => (
            <ReferenceInput
              {...rest}
              source="module_type.id"
              reference="modules"
              perPage={100}
              allowEmpty
              label="module"
              onChange={() => dispatch(change(REDUX_FORM_NAME, 'answers', null))}>
                <SelectInput optionText="name" />
            </ReferenceInput>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {// eslint-disable-next-line no-unused-vars
          ({ formData, dispatch, ...rest }) => (
            (formData.module_type && formData.module_type.id) === MODULE_IDS.ID_TALENT_ACQUISITION &&
              <ReferenceInput
                {...rest}
                source="profile"
                reference="profiles"
                perPage={100}
                validate={[required()]}
                onChange={() => dispatch(change(REDUX_FORM_NAME, 'answers', null))}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
          )}
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData &&
            formData.profile && (
              <ReferenceArrayInput
                {...rest}
                key={`answers_${formData.profile}_${count}`}
                source="answers"
                reference={formData.profile === API_PROFILE_HARD_ID ? 'answers_profile' : 'answers_skills'}
                perPage={100}
                filterToQuery={searchText => ({ name: searchText, question: null })}
                onChange={() => setTimeout(() => setCount(count + 1), 250)}
                allowEmpty
                filter={{ question: null }}
              >
                <AutocompleteArrayInput {...rest} optionText="name" shouldRenderSuggestions={() => true} inputValueMatcher={() => true} />
              </ReferenceArrayInput>
            )
          }
        </FormDataConsumer>

        <FormDataConsumer>
          {({ formData }) =>
            formData.profile
            && formData.module_type.id === MODULE_IDS.ID_TALENT_ACQUISITION
            && formData.profile === API_PROFILE_HARD_ID
            && <NumberInput source="weight" validate={[required()]} />}
        </FormDataConsumer>

      </SimpleForm>
    </Create>
  )
}

export default QuestionsCreate
