import _ from 'lodash'
import { CREATE, UPDATE } from 'react-admin'

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file.rawFile)
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

const addUploadFeature = requestHandler => (type, resource, params) => {
  if ((type === UPDATE || type === CREATE) && resource === 'users') {
    if (params.data.avatar && params.data.avatar.rawFile instanceof File) {
      return convertFileToBase64(params.data.avatar).then(imageBase64 =>
        requestHandler(type, resource, {
          ...params,
          data: {
            ..._.omit(params.data, 'avatar'),
            imageBase64
          }
        })
      )
    }
  } else if ((type === UPDATE || type === CREATE) && resource === 'clients') {
    if (params.data.logo && params.data.logo.rawFile instanceof File) {
      return convertFileToBase64(params.data.logo).then(imageBase64 =>
        requestHandler(type, resource, {
          ...params,
          data: {
            ..._.omit(params.data, 'logo'),
            imageBase64
          }
        })
      )
    }
  }

  return requestHandler(type, resource, params)
}

export default addUploadFeature
