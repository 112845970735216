import React from 'react'
import { Edit, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput } from 'react-admin'

const NotInterestedTagsEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextInput source="label" />
      <NumberInput source="position" />
      <ReferenceInput source="client" reference="clients" perPage={100}>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export default NotInterestedTagsEdit
