import { stringify } from 'query-string'
import { CREATE, DELETE, DELETE_MANY, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE, UPDATE, UPDATE_MANY } from 'react-admin'
import _ from 'lodash'
import authHttpClient from './httpClient'
import { MODULE_IDS } from '../../config/api'

const getId = (json, key) => _.get(json, `${key}.id`)
const getIds = (json, key) => _.map(_.get(json, key, []), ({ id }) => id)
const parseId = (params, key) => ({ id: _.get(params, `data.${key}`) })
const parseIds = (params, key) => _.map(_.get(params, `data.${key}`), (id) => ({ id }))

const customCreateOrUpdateRequest = (apiUrl, type, resource, params, customParams) => {
  const url = type === CREATE ? `${apiUrl}/${resource}` : `${apiUrl}/${resource}/${params.id}`
  const options = {
    method: type === CREATE ? 'POST' : 'PUT',
    body: JSON.stringify({
      ..._.omit(params.data, ['id']),
      ...customParams,
    }),
  }
  return { url, options }
}

const isCreateOrUpdate = (type) => type === CREATE || type === UPDATE

const candidaturesExtractIds = (json) => {
  return {
    ...json,
    client: getId(json, 'client'),
    skill1: getId(json, 'skill1'),
    skill2: getId(json, 'skill2'),
    skill3: getId(json, 'skill3'),
    skill4: getId(json, 'skill4'),
    questions: getIds(json, 'questions'),
  }
}

const getTotal = (headers) => parseInt(headers.get('content-range').split('/').pop(), 10)

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['name','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = authHttpClient) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    if (isCreateOrUpdate(type) && resource === 'candidatures') {
      return customCreateOrUpdateRequest(apiUrl, type, resource, params, {
        client: parseId(params, 'client'),
        skill1: parseId(params, 'skill1'),
        skill2: parseId(params, 'skill2'),
        skill3: parseId(params, 'skill3'),
        skill4: parseId(params, 'skill4'),
        questions: parseIds(params, 'questions'),
      })
    } else if (isCreateOrUpdate(type) && resource === 'clients') {
      return customCreateOrUpdateRequest(apiUrl, type, resource, params, {
        smtp_config: parseId(params, 'smtp_config'),
        modules: parseIds(params, 'modules'),
        candidatures: parseIds(params, 'candidatures'),
        technical_selection_users: parseIds(params, 'technical_selection_users'),
        default_messages: parseIds(params, 'default_messages'),
        inboxes: parseIds(params, 'inboxes'),
        filters: parseIds(params, 'filters'),
        chat_message_email: parseId(params, 'chat_message_email'),
      })
    } else if (isCreateOrUpdate(type) && resource === 'answers_skills') {
      return customCreateOrUpdateRequest(apiUrl, type, resource, params, {
        skill: parseId(params, 'skill'),
        axis1: parseId(params, 'axis1'),
        mbti_value_1: parseId(params, 'mbti_value_1'),
        axis2: parseId(params, 'axis2'),
        mbti_value_2: parseId(params, 'mbti_value_2'),
        axis3: parseId(params, 'axis3'),
        mbti_value_3: parseId(params, 'mbti_value_3'),
        axis4: parseId(params, 'axis4'),
        mbti_value_4: parseId(params, 'mbti_value_4'),
      })
    } else if (isCreateOrUpdate(type) && resource === 'questions') {
      return customCreateOrUpdateRequest(apiUrl, type, resource, params, {
        profile: parseId(params, 'profile'),
        answers: parseIds(params, 'answers'),
      })
    } else if (isCreateOrUpdate(type) && resource === 'availabilities') {
      return customCreateOrUpdateRequest(apiUrl, type, resource, params, {
        client: parseId(params, 'client'),
      })
    } else if (isCreateOrUpdate(type) && resource === 'disinteresteds') {
      return customCreateOrUpdateRequest(apiUrl, type, resource, params, {
        client: parseId(params, 'client'),
      })
    }

    let url = ''
    const options = {}
    switch (type) {
      case GET_LIST: {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify(params.filter),
        }
        url = `${apiUrl}/${resource}?${stringify(query)}`

        //console.log(type, resource, params)
        break
      }
      case GET_ONE:
        url = `${apiUrl}/${resource}/${params.id}`
        break
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({ id: params.ids }),
        }
        url = `${apiUrl}/${resource}?${stringify(query)}`
        break
      }
      case GET_MANY_REFERENCE: {
        const { page, perPage } = params.pagination
        const { field, order } = params.sort
        const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify({
            ...params.filter,
            [params.target]: params.id,
          }),
        }
        url = `${apiUrl}/${resource}?${stringify(query)}`
        break
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'PUT'
        options.body = JSON.stringify(_.omit(params.data, ['id']))
        break
      case CREATE:
        url = `${apiUrl}/${resource}`
        options.method = 'POST'
        options.body = JSON.stringify(params.data)
        break
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'DELETE'
        break
      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }

    return { url, options }
  }

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    let { headers, json } = response
    //console.log(response, type, resource, params)
    if (resource === 'clients') {
      let prepareData = function (json) {
        json.client_has_modules.forEach((el) => {
          el.enabled = true
          el.inbox_fields = getIds(el, 'inbox_fields')
          json['module_' + el.module_type.id] = el
        })
        for (const moduleId in MODULE_IDS) {
          if (!json['module_' + MODULE_IDS[moduleId]]) {
            json['module_' + MODULE_IDS[moduleId]] = { enabled: false }
          }
        }
      }
      if (type === GET_ONE) {
        prepareData(json)
        return {
          data: json,
        }
      } else if (type === GET_LIST) {
        json.forEach((el) => {
          prepareData(el)
        })
        return {
          data: json,
          total: getTotal(headers),
        }
      }
    } else if (type === GET_ONE && resource === 'candidatures') {
      return {
        data: candidaturesExtractIds(json)
      }
    } else if (type === GET_LIST && resource === 'candidatures') {
      json = json.map((rowJson) => candidaturesExtractIds(rowJson))

    } else if (type === GET_ONE && (resource === 'processes' || resource === 'onboardingconfigs' || resource === 'suggestionfeedbackconfigs' || resource === 'exitinterviewconfigs' )) {
      return {
        data: {
          ...json,
          questions: getIds(json, 'questions'),
        },
      }
    } else if (type === GET_ONE && resource === 'types/mbtis') {
      return {
        data: {
          ...json,
          axis: getId(json, 'axis'),
        },
      }
    } else if (type === GET_ONE && resource === 'mbtis') {
      return {
        data: {
          ...json,
          axis: getId(json, 'axis'),
          value: getId(json, 'value'),
          answer: getId(json, 'answer'),
        },
      }
    } else if (type === GET_ONE && resource === 'axis') {
      return {
        data: {
          ...json,
          values: getIds(json, 'values'),
        },
      }
    } else if (type === GET_ONE && resource === 'answers_skills') {
      return {
        data: {
          ...json,
          skill: getId(json, 'skill'),
          mbtis: getIds(json, 'mbtis'),
          axis1: getId(json, 'axis1'),
          mbti_value_1: getId(json, 'mbti_value1'),
          axis2: getId(json, 'axis2'),
          mbti_value_2: getId(json, 'mbti_value2'),
          axis3: getId(json, 'axis3'),
          mbti_value_3: getId(json, 'mbti_value3'),
          axis4: getId(json, 'axis4'),
          mbti_value_4: getId(json, 'mbti_value4'),
        },
      }
    } else if (type === GET_ONE && resource === 'questions') {
      return {
        data: {
          ...json,
          profile: getId(json, 'profile'),
          answers: getIds(json, 'answers'),
        },
      }
    } else if (type === GET_ONE && resource === 'default_messages') {
      return {
        data: {
          ...json,
          client: getId(json, 'client'),
        },
      }
    } else if (type === GET_ONE && resource === 'availabilities') {
      return {
        data: {
          ...json,
          client: getId(json, 'client'),
        },
      }
    } else if (type === GET_ONE && resource === 'disinteresteds') {
      return {
        data: {
          ...json,
          client: getId(json, 'client'),
        },
      }
    }

    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!headers.has('content-range')) {
          throw new Error(
            'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
          )
        }

        return { data: json, total: getTotal(headers) }
      case CREATE:
        return { data: { ...params.data, id: json.id } }
      default:
        return { data: json }
    }
  }

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }))
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map((id) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          })
        )
      ).then((responses) => ({
        data: responses.map((response) => response.json),
      }))
    }

    const { url, options } = convertDataRequestToHTTP(type, resource, params)
    return httpClient(url, options).then((response) => convertHTTPResponse(response, type, resource, params))
  }
}
