import React, {useEffect, useState} from 'react';
import {getClientsFormattedList, getSmsReportsFetchData} from '../../../../api';
import {find, isNull} from "lodash";
import DataTable from 'react-data-table-component';
import {MultiSelect} from "react-multi-select-component";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import Spinner from "../../../../assets/images/spinner_ring.gif"
import * as XLSX from "xlsx";
import i18n from "../../../../assets/i18n";

registerLocale("es", es);

const SmsReportsList = () => {

  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [clientsList, setClientsList] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [smsData, setSmsData] = useState([]);
  const [whatsappData, setWhatsappData] = useState([]);
  const [emailColumns, setEmailColumns] = useState([]);
  const [smsColumns, setSmsColumns] = useState([]);
  const [whatsappColumns, setWhatsappColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisable, setIsDisable] = useState(true);
  const NOT_RELIABLE_DATA = [
    'ALAIN AFFLELOU',
    'DIA',
    'Eurofirms Logística',
    'Eurofirms Teleoperador ATC',
    'Eurofirms Chile',
    'Eurofirms Teleoperador VTS'
  ];

  useEffect(() => {
    initStatsPage();
  }, []);

  const initialDate = () => {
    let date = new Date();
    let start = new Date(date.getFullYear(), date.getMonth(), 1);
    start.setHours(2);
    return start;
  }

  const initStatsPage = () => {
    getClientsFormattedList().then((res) => {
      let clients = res.json.sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
      setClientsList(clients);
    });

    let lastDate = new Date();
    let firstDate = new Date();//initialDate();

    setFrom(firstDate);
    setTo(lastDate);

    firstDate = firstDate.toISOString().substring(0, 10);
    lastDate = lastDate.toISOString().substring(0, 10);

    getSmsReportsFetchData(firstDate, lastDate).then((res) => {
      let data = res.json;
      let summaryData = removeObjectKeys(data.summary);
      summaryData.map(row => {
        if (NOT_RELIABLE_DATA.includes(row['client'])) {
          row['candidature_config'] += '*';
        }
        return row;
      });
      setSummaryData(summaryData);
      formatEmailSmsWhatsappDataAndColumns(data.email, 'email');
      formatEmailSmsWhatsappDataAndColumns(data.sms, 'sms');
      formatEmailSmsWhatsappDataAndColumns(data.whatsapp, 'whatsapp');

    }).finally(() => {
      setIsLoading(false);
      setIsDisable(false);
    });
  }

  const removeObjectKeys = (data) => {
    let dataKeys = Object.keys(data);
    let resultData = [];

    for (let i = 0; i < dataKeys.length; i++) {
      resultData.push(data[dataKeys[i]]);
    }
    return resultData;
  }

  const sortColumns = (columns) => {
    return columns.sort((a, b) => {
      if (a.name === b.name) return 0;

      const datePattern = /^\d{2}\/\d{2}\/\d{4}/;
      if (isNull(b.name.match(datePattern))) return 1;

      let date1 = a.name.split('/');
      let date2 = b.name.split('/');

      date1 = new Date(date1[2] + '/' + date1[1] + '/' + date1[0]);
      date2 = new Date(date2[2] + '/' + date2[1] + '/' + date2[0]);

      if (date1 > date2) return 1;
      if (date1 < date2) return -1;
    });
  }

  const formatEmailSmsWhatsappDataAndColumns = (data, method) => {
    let processingData = {};
    let columns = [{id: 1, name: 'Cliente', style: () => ({minWidth: 150}), selector: (row) => row.client, sortable: true, reorder: true}];

    data.map((val, key) => {
      let date = val.date.split('-');
      date = date.reverse().join('/');
      if (!processingData.hasOwnProperty(val.client)) {
        processingData[val.client] = {client: val.client};
      }
      processingData[val.client][date] = val.sent;

      if (!find(columns, {name: date})) {
        columns.push({id: key + 2, name: date, selector: (row) => row[date], sortable: true, reorder: true})
      }
    });

    let totalColumnId = columns[columns.length - 1].id;
    // eslint-disable-next-line
    columns.push({id: totalColumnId+1, name: "Total", selector: (row) => <b>{row["total"]}</b>, sortable: true, reorder: false})

    let resultData = removeObjectKeys(processingData);

    let totalsData = {client: 'Total', total: 0};

    resultData.map(row => {
      let keys = Object.keys(row);
      let total = 0;
      keys.map(key => {
        if (key !== 'client') {
          if (!totalsData.hasOwnProperty(key)) {
            totalsData[key] = 0;
          }
          totalsData[key] += row[key];
          totalsData['total'] += row[key];
          total += row[key];
        }
      });
      row['total'] = total;
      return row;
    });

    resultData.push(totalsData);
    columns = sortColumns(columns);

    switch (method) {
      case 'email':
        setEmailData(resultData);
        setEmailColumns(columns);
        break;
      case 'sms':
        setSmsData(resultData);
        setSmsColumns(columns);
        break;
      case 'whatsapp':
        setWhatsappData(resultData);
        setWhatsappColumns(columns);
        break;
      default:
        break;
    }
  }

  const submitData = () => {
    from.setHours(2);
    let start = from.toISOString().substring(0, 10)
    let end = to.toISOString().substring(0, 10)
    let clients = selectedClients.map(item => item.value)

    setIsLoading(true);
    getSmsReportsFetchData(start, end, clients).then((res) => {
      let data = res.json;
      let summaryData = removeObjectKeys(data.summary);
      setSummaryData(summaryData);
      formatEmailSmsWhatsappDataAndColumns(data.email, 'email');
      formatEmailSmsWhatsappDataAndColumns(data.sms, 'sms');
      formatEmailSmsWhatsappDataAndColumns(data.whatsapp, 'whatsapp');

    }).finally(() => setIsLoading(false));
  }

  const multiSelectTranslations = {
    "allItemsAreSelected": "Se han seleccionado todas las opciones.",
    "clearSearch": "Borrar búsqueda",
    "clearSelected": "Borrar selección",
    "noOptions": "No hay opciones",
    "search": "Buscar",
    "selectAll": "Seleccionar todo",
    "selectAllFiltered": "Seleccionar todo (filtrado)",
    "selectSomeItems": "Seleccionar...",
    "create": "Crear",
  };

  const sortNumbersAndPercentages = (rowA, rowB, field) => {
    if (Number(rowA[field]) > Number(rowB[field])) {
      return 1;
    }
    if (Number(rowA[field]) < Number(rowB[field])) {
      return -1;
    }
    return 0;
  }

  const summaryColumns = [
    {
      id: 1,
      name: i18n.es.sms_reports.client,
      selector: (row) => row.client,
      style: () => ({minWidth: 150}),
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 2,
      name: i18n.es.sms_reports.candidature_config,
      selector: (row) => row.candidature_config,
      style: () => ({minWidth: 150}),
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 3,
      name: i18n.es.sms_reports.unique_candidates,
      selector: (row) => row.unique_candidates,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 4,
      name: i18n.es.sms_reports.candidates_applied_email,
      selector: (row) => row.candidates_applied_email,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 5,
      name: i18n.es.sms_reports.sent_email,
      selector: (row) => row.sent_email ? row.sent_email.toFixed(1) + '%' : '0 %',
      sortable: true,
      reorder: true,
      wrap: true,
      sortFunction: (a, b) => {
        return sortNumbersAndPercentages(a, b, 'sent_email')
      }
    },
    {
      id: 6,
      name: i18n.es.sms_reports.loaded_email,
      selector: (row) => row.loaded_email,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 7,
      name: i18n.es.sms_reports.opened_email,
      selector: (row) => row.opened_email ? row.opened_email.toFixed(1) + '%' : '0 %',
      sortable: true,
      reorder: true,
      wrap: true,
      style: () => ({fontWeight: 'bold'}),
      sortFunction: (a, b) => {
        return sortNumbersAndPercentages(a, b, 'opened_email')
      }
    },
    {
      id: 8,
      name: i18n.es.sms_reports.candidates_applied_sms,
      selector: (row) => row.candidates_applied_sms,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 9,
      name: i18n.es.sms_reports.sent_sms,
      selector: (row) => row.sent_sms ? row.sent_sms.toFixed(1) + '%' : '0 %',
      sortable: true,
      reorder: true,
      wrap: true,
      sortFunction: (a, b) => {
        return sortNumbersAndPercentages(a, b, 'sent_sms')
      }
    },
    {
      id: 10,
      name: i18n.es.sms_reports.loaded_sms,
      selector: (row) => row.loaded_sms,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 11,
      name: i18n.es.sms_reports.opened_sms,
      selector: (row) => row.opened_sms ? row.opened_sms.toFixed(1) + '%' : '0 %',
      sortable: true,
      reorder: true,
      wrap: true,
      style: () => ({fontWeight: 'bold'}),
      sortFunction: (a, b) => {
        return sortNumbersAndPercentages(a, b, 'opened_sms')
      }
    },
    {
      id: 12,
      name: i18n.es.sms_reports.candidates_applied_whatsapp,
      selector: (row) => row.candidates_applied_whatsapp,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 13,
      name: i18n.es.sms_reports.sent_whatsapp,
      selector: (row) => row.sent_whatsapp ? row.sent_whatsapp.toFixed(1) + '%' : '0 %',
      sortable: true,
      reorder: true,
      wrap: true,
      sortFunction: (a, b) => {
        return sortNumbersAndPercentages(a, b, 'sent_whatsapp')
      }
    },
    {
      id: 14,
      name: i18n.es.sms_reports.loaded_whatsapp,
      selector: (row) => row.loaded_whatsapp,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 15,
      name: i18n.es.sms_reports.opened_whatsapp,
      selector: (row) => row.opened_whatsapp ? row.opened_whatsapp.toFixed(1) + '%' : '0 %',
      sortable: true,
      reorder: true,
      wrap: true,
      style: () => ({fontWeight: 'bold'}),
      sortFunction: (a, b) => {
        return sortNumbersAndPercentages(a, b, 'opened_whatsapp')
      }
    },
    {
      id: 16,
      name: i18n.es.sms_reports.loaded,
      selector: (row) => row.loaded,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 17,
      name: i18n.es.sms_reports.bots_done,
      selector: (row) => row.bots_done,
      sortable: true,
      reorder: true,
      wrap: true,
    },
    {
      id: 18,
      name: i18n.es.sms_reports.opened_percentage,
      selector: (row) => row.opened_percentage ? row.opened_percentage.toFixed(1) + '%' : '0 %',
      sortable: true,
      reorder: true,
      wrap: true,
      sortFunction: (a, b) => {
        return sortNumbersAndPercentages(a, b, 'opened_percentage')
      }
    },
    {
      id: 19,
      name: i18n.es.sms_reports.applied_percentage,
      selector: (row) => row.applied_percentage ? row.applied_percentage.toFixed(1) + '%' : '0 %',
      sortable: true,
      reorder: true,
      wrap: true,
      style: () => ({fontWeight: 'bold'}),
      sortFunction: (a, b) => {
        return sortNumbersAndPercentages(a, b, 'applied_percentage')
      }
    },
  ];

  const paginationOptions = {
    rowsPerPageText: i18n.es.sms_reports.rowsPerPageText,
    rangeSeparatorText: i18n.es.sms_reports.rangeSeparatorText,
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: i18n.es.sms_reports.selectAllRowsItemText,
  };

  const   downloadXLS = (data, section) => {
    if (section === 'summary') {
      data = data.map(row => {
        const keys = ['client', 'candidature_config', 'unique_candidates','candidates_applied_email', 'sent_email', 'loaded_email', 'opened_email','candidates_applied_sms', 'sent_sms', 'loaded_sms', 'opened_sms','candidates_applied_whatsapp', 'sent_whatsapp', 'loaded_whatsapp', 'opened_whatsapp', 'loaded', 'bots_done', 'opened_percentage', 'applied_percentage'];
        let newRow = {};
        keys.map((key) => {
          newRow[i18n.es.sms_reports[key]] = row[key];
        });
        return newRow;
      });
    }

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    let date = new Date();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, `sms_reports_${section}_${date.getUTCDate()}-${date.getUTCMonth()+1}-${date.getUTCFullYear()}.xlsx`);
  }

  const createTable = (title, section, columns, data, defaultSortFieldId = 0) => {
    return <DataTable
      title={title}
      columns={columns}
      defaultSortFieldId={defaultSortFieldId}
      defaultSortAsc={true}
      data={data}
      actions={<button className='action_button' onClick={() => downloadXLS(data, section)}>{i18n.es.sms_reports.export}</button>}
      pagination
      striped
      highlightOnHover
      responsive
      paginationComponentOptions={paginationOptions}
      paginationPerPage={50}
      paginationRowsPerPageOptions={[50, 100, 200]}
    />
  }

  return (<>
    <div className="sms_report_filters_parent_container">
      <div className="sms_report_filter_container">
        <p>Desde:</p>
        <DatePicker
          selected={from}
          onChange={(date) => date ? setFrom(date) : setFrom(initialDate())}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={15}
          scrollableYearDropdown
          minDate={new Date("01-01-2020")}
          maxDate={to ? to : new Date()}
          dateFormat="dd/MM/yyyy"
          locale={es}
        />
      </div>
      <div className="sms_report_filter_container">
        <p>Hasta:</p>
        <DatePicker
          selected={to}
          onChange={(date) => date ? setTo(date) : setTo(new Date())}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={15}
          scrollableYearDropdown
          minDate={from ? from : new Date("01-01-2020")}
          maxDate={new Date()}
          dateFormat="dd/MM/yyyy"
          locale={es}
        />
      </div>
      <div className="sms_report_filter_container">
        <p>Clientes:</p>
        <MultiSelect
          options={clientsList}
          value={selectedClients}
          onChange={setSelectedClients}
          labelledBy="Select"
          overrideStrings={multiSelectTranslations}
        />
      </div>
      <button className="action_button" disabled={isDisable} onClick={submitData}
              style={isDisable ? {opacity: 0.3, cursor: "not-allowed"} : {}}>
        Enviar
      </button>
    </div>
    <div className="content_container">
      {!isLoading ?
        <div>
          <div className="summary_table">
            {createTable(i18n.es.sms_reports.title.summary, "summary", summaryColumns, summaryData, 1)}
          </div>
          <div className="email_table">
            {createTable(i18n.es.sms_reports.title.email, "email", emailColumns, emailData)}
          </div>
          <div className="sms_table">
            {createTable(i18n.es.sms_reports.title.sms, "sms", smsColumns, smsData)}
          </div>
          <div className="whatsapp_table">
            {createTable(i18n.es.sms_reports.title.whatsapp, "whatsapp", whatsappColumns, whatsappData)}
          </div>
        </div>
        : <img src={Spinner} alt={i18n.es.sms_reports.loading} width={120} height={120} style={{marginTop: 150}}/>}
    </div>
  </>)
}

export default SmsReportsList