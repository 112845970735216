import React from 'react'
import {Datagrid, ReferenceArrayField, ReferenceField, Show, Tab, TabbedShowLayout, TextField} from 'react-admin'

const CandidaturesShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name"/>
        <TextField source="remote_name"/>
        <TextField source="prefix"/>

        <ReferenceField source="client.id" reference="clients">
          <TextField source="name" />
        </ReferenceField>

        <TextField source="type.name" label="processType"/>
      </Tab>

      <Tab label="resources.processes.tabs.questions">
        <ReferenceArrayField source="questions" reference="questions" addLabel={false}>
          <Datagrid rowClick="show">
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="internal_name" />
            <TextField source="weight" />
          </Datagrid>
        </ReferenceArrayField>
      </Tab>

    </TabbedShowLayout>
  </Show>
)

export default CandidaturesShow
