import React from 'react'
import { List, Datagrid, TextField, NumberField, Filter, TextInput } from 'react-admin'

const AnswersSkillsFilter = props => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="name" />
    <TextInput source="value" />
  </Filter>
)

const AnswersProfilesList = props => (
  <List {...props} exporter={false} filters={<AnswersSkillsFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="value" />
      <NumberField source="score" />
    </Datagrid>
  </List>
)

export default AnswersProfilesList
