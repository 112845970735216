import React from 'react'
import {Edit, SimpleForm, TextInput, required, SelectInput, ReferenceInput} from 'react-admin'

const InboxesEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect="show">
        <TextInput source="name" validate={[required()]} />
        <TextInput source="remote_name" validate={[required()]} />
        <ReferenceInput source="module_type.id" reference="modules" perPage={100} allowEmpty label="module">
          <SelectInput optionText="name" />
        </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export default InboxesEdit
