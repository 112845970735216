import React from 'react'
import { Show, TabbedShowLayout, Tab } from 'react-admin'
import { ReferenceField, TextField, BooleanField, ChipField } from 'react-admin'

const AnswersSkillsShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="value" />
        <BooleanField source="punctuate" />

        <ReferenceField source="skill" reference="skills" linkType={false}>
          <ChipField source="name" />
        </ReferenceField>

        <ReferenceField source="axis1" reference="axis" linkType={false}>
          <ChipField source="name" />
        </ReferenceField>
        <ReferenceField source="mbti_value_1" reference="types/mbtis" linkType={false}>
          <ChipField source="value" />
        </ReferenceField>

        <ReferenceField source="axis2" reference="axis" linkType={false}>
          <ChipField source="name" />
        </ReferenceField>
        <ReferenceField source="mbti_value_2" reference="types/mbtis" linkType={false}>
          <ChipField source="value" />
        </ReferenceField>

        <ReferenceField source="axis3" reference="axis" linkType={false}>
          <ChipField source="name" />
        </ReferenceField>
        <ReferenceField source="mbti_value_3" reference="types/mbtis" linkType={false}>
          <ChipField source="value" />
        </ReferenceField>

        <ReferenceField source="axis4" reference="axis" linkType={false}>
          <ChipField source="name" />
        </ReferenceField>
        <ReferenceField source="mbti_value_4" reference="types/mbtis" linkType={false}>
          <ChipField source="value" />
        </ReferenceField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default AnswersSkillsShow
