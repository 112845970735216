import React from 'react'
import { Create, SimpleForm, TextInput, NumberInput, required } from 'react-admin'

const AnswersProfilesCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={[required()]} />
      <TextInput source="value" validate={[required()]} />
      <NumberInput source="score" validate={[required()]} />
    </SimpleForm>
  </Create>
)

export default AnswersProfilesCreate
