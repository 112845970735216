import _ from 'lodash'

const fetchJson = (url, options = {}) => {
  const requestHeaders =
    options.headers ||
    new Headers({
      Accept: 'application/json'
    })
  if (!requestHeaders.has('Content-Type') && !(options && options.body && options.body instanceof FormData)) {
    requestHeaders.set('Content-Type', 'application/json')
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token)
  }

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json
      try {
        json = JSON.parse(body)
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        let msg = (json && json.message) || statusText

        if (json && json.errors && json.errors.children) {
          msg = ''
          _.map(json.errors.children, (value, key) => {
            if (value.errors && value.errors.length > 0) {
              msg = msg + key + ': ' + value.errors[0]
            }
          })
          if (!msg && _.size(json.errors.errors)) {
            if (typeof json.errors.errors[0] === 'string') {
              msg = json.errors.errors[0]
            }
          }
        }

        return Promise.reject(new HttpError(msg, status, json))
      }

      return { status, headers, body, json }
    })
}

class HttpError extends Error {
  constructor(message, status, body = null) {
    super(message)
    this.message = message
    this.status = status
    this.body = body
    this.name = this.constructor.name
    if (typeof Error.captureStackTrace === 'function') {
      return Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }
    this.stack = new Error().stack
  }
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value
      }
    })
  }
  return changes(object, base)
}

export { fetchJson, difference }
