import React from 'react'
import { Create, SimpleForm, TextInput, BooleanInput, ReferenceInput, SelectInput, required } from 'react-admin'
import { AxisReferenceInput, MbtisReferenceInput } from '../../../atoms'

const AnswersSkillsCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={[required()]} />
      <TextInput source="value" validate={[required()]} />
      <ReferenceInput source="skill" reference="skills" sort={{ field: 'name', order: 'ASC' }} perPage={1000} validate={[required()]}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="punctuate" />

      <AxisReferenceInput source="axis1" field="mbti_value_1" />
      <MbtisReferenceInput source="mbti_value_1" field="axis1" />

      <AxisReferenceInput source="axis2" field="mbti_value_2" />
      <MbtisReferenceInput source="mbti_value_2" field="axis2" />

      <AxisReferenceInput source="axis3" field="mbti_value_3" />
      <MbtisReferenceInput source="mbti_value_3" field="axis3" />

      <AxisReferenceInput source="axis4" field="mbti_value_4" />
      <MbtisReferenceInput source="mbti_value_4" field="axis4" />
    </SimpleForm>
  </Create>
)

export default AnswersSkillsCreate
