import React from 'react'
import { Edit, NumberInput, ReferenceInput, required, SelectInput, SimpleForm, TextInput } from 'react-admin'
import {ColorInput} from "react-admin-color-input";

const ReminderConfigsEdit = (props) => {
  return (
    <Edit {...props} undoable={false}>
      <SimpleForm redirect="show">
        <ReferenceInput source="client.id" reference="clients" perPage={100} validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="module_type.id" reference="modules" perPage={100} label="module" validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="name" validate={required()} />
        <TextInput source="description" validate={required()} />
        <ColorInput source="color" />
        <NumberInput source="order" validate={required()} />
      </SimpleForm>
    </Edit>
  )
}

export default ReminderConfigsEdit
