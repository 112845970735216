import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
} from 'react-admin'

const ClientsFiltersBlockFilter = props => (
  <Filter {...props}>
    <TextInput source="id" />
    <TextInput source="name" label="Nombre" />   
  </Filter>
);

const FiltersBlockList = props => (
  <List {...props} exporter={false} filters={<ClientsFiltersBlockFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" label="Nombre"/>
      <TextField source="order" label="Orden" />
    </Datagrid>
  </List>
);

export default FiltersBlockList;
