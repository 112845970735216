import { fetchJson } from './fetchUtils'
import { getAuthToken } from '../authProvider'
export default (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = getAuthToken()
  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchJson(url, options)
}
