import { createMuiTheme } from '@material-ui/core/styles'
import colors from '../assets/colors'

const theme = createMuiTheme({
  colors,
  palette: {
    primary: {
      main: colors.blue,
      contrastText: colors.white
    },
    secondary: {
      main: colors.blue,
      contrastText: colors.white
    },
    error: {
      main: colors.red
    }
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: colors.blue
        }
      }
    }
  }
})

export default theme
