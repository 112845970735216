import React, {useEffect, useState} from 'react';
import {getGeroChatbots, getGeroSessions} from '../../../../api';
import DataTable from 'react-data-table-component';
import {MultiSelect} from "react-multi-select-component";
import {Dialog} from '../../../molecules'
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import Spinner from "../../../../assets/images/spinner_ring.gif"
//import * as XLSX from "xlsx";
import i18n from "../../../../assets/i18n";
import {ButtonFilter, FilterContainer, FilterItem, InputFilter, TableWrapper} from "./styled";
registerLocale("es", es);

const GeroSessions = () => {

  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());
  const [chatbotList, setChatbotList] = useState([]);
  const [chatbotIndexed, setChatbotIndexed] = useState({});
  const [selectedChatbots, setSelectedChatbots] = useState([]);
  const [valorVariable, setValorVariable] = useState("");
  const [nombreVariable, setNombreVariable] = useState("");
  const [sessions, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isDisable, setIsDisable] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [currentSession, setCurrentSession] = useState(null);


  useEffect(() => {
    initSessions();
  }, []);

  const initialDate = () => {
    let date = new Date();
    let start = new Date(date.getFullYear(), date.getMonth(), 1);
    start.setHours(2);
    return start;
  }

  const initSessions = () => {
    getGeroChatbots().then((res) => {
      let chatbots = res.json.map(c => {return {"name":c.id, "value":c.id, "label": c.name + " ("+c.id+")"} }).sort((a, b) => a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1);
      setChatbotList(chatbots);
      let chatbots_index = {};
      res.json.forEach((e)=> chatbots_index[e.id] = e.name)
      setChatbotIndexed(chatbots_index);
    });

    let lastDate = new Date();
    let firstDate = new Date();//initialDate();

    setFrom(firstDate);
    setTo(lastDate);

    firstDate = firstDate.toISOString().substring(0, 10);
    lastDate = lastDate.toISOString().substring(0, 10);

    getGeroSessions(firstDate, lastDate).then((res) => {
      let data = res.json;
      setSessions(data);
    }).finally(() => {
      setIsLoading(false);
      setIsDisable(false);
    });
  }


  const submitData = () => {
    from.setHours(2);
    let start = from.toISOString().substring(0, 10)
    let end = to.toISOString().substring(0, 10)
    let chatbots = selectedChatbots.map(item => item.value)
    let variables = {}
    if(valorVariable.length && nombreVariable.length){
      variables[nombreVariable] = valorVariable;
    }

    setIsLoading(true);
    getGeroSessions(start, end, chatbots, variables).then((res) => {
      let data = res.json;
      setSessions(data);
    }).finally(() => setIsLoading(false));
  }

  const multiSelectTranslations = {
    "allItemsAreSelected": "Se han seleccionado todas las opciones.",
    "clearSearch": "Borrar búsqueda",
    "clearSelected": "Borrar selección",
    "noOptions": "No hay opciones",
    "search": "Buscar",
    "selectAll": "Seleccionar todo",
    "selectAllFiltered": "Seleccionar todo (filtrado)",
    "selectSomeItems": "Seleccionar...",
    "create": "Crear",
  };

  const openSession = function(session){
    setCurrentSession(session);
    setIsOpen(true);
  }

  const sessionsColumns = [
    {
      id: 1,
      name: "ID",
      selector: (row) => <a onClick={() => openSession(row)}>{row.id}</a>,
      style: () => ({width: 300, minWidth: 300, textDecoration: 'underline', color: "blue", cursor: "pointer"}),
      sortable: false,
      reorder: false,
      wrap: true,
    },
    {
      id: 2,
      name: "Chatbot",
      selector: (row) => chatbotIndexed[row.chatbot.id],
      style: () => ({width: 160}),
      sortable: false,
      reorder: false,
      wrap: true,
    },
    {
      id: 3,
      name: "Mensajes enviados",
      style: () => ({maxWidth: 40}),
      selector: (row) => row.visited_nodes,
      sortable: false,
      reorder: false,
      wrap: true,
    },
    {
      id: 4,
      name: "Email",
      style: () => ({width: 240}),
      selector: (row) => row.variables.email || '-',
      sortable: false,
      reorder: false,
      wrap: true,
    },
    {
      id: 5,
      name: "Nombre",
      style: () => ({width: 240}),
      selector: (row) => row.variables.nombre ? row.variables.nombre +" "+ row.variables.apellidos : '-',
      sortable: false,
      reorder: false,
      wrap: true,
    },
    {
      id: 6,
      name: "Inicio de sesión",
      style: () => ({width: 240}),
      selector: (row) => row.started_at ? row.started_at.replace("T", "") : '-',
      sortable: false,
      reorder: false,
      wrap: true,
    },
    {
      id: 7,
      name: "Primer guardado",
      style: () => ({width: 240}),
      selector: (row) => row.first_saved_at ? row.first_saved_at.replace("T", "") : '-',
      sortable: false,
      reorder: false,
      wrap: true,
    },
    {
      id: 8,
      name: "Último guardado",
      style: () => ({width: 240}),
      selector: (row) => row.last_saved_at ? row.last_saved_at.replace("T", "") : '-',
      sortable: false,
      reorder: false,
      wrap: true,
    },
    {
      id: 9,
      name: "Entrevista finalizada",
      style: () => ({width: 240}),
      selector: (row) => row.finished_at ? row.finished_at.replace("T", "") : '-',
      sortable: false,
      reorder: false,
      wrap: true,
    }
  ];

  const paginationOptions = {
    rowsPerPageText: i18n.es.sms_reports.rowsPerPageText,
    rangeSeparatorText: i18n.es.sms_reports.rangeSeparatorText,
    noRowsPerPage: false,
    selectAllRowsItem: false,
    selectAllRowsItemText: i18n.es.sms_reports.selectAllRowsItemText,
  };

  /*const downloadXLS = (data, section) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    let date = new Date();
    XLSX.utils.book_append_sheet(wb, ws, "Report");
    XLSX.writeFile(wb, `gero_sessions_${section}_${date.getUTCDate()}-${date.getUTCMonth()+1}-${date.getUTCFullYear()}.xlsx`);
  }*/

  const createTable = (title, section, columns, data, defaultSortFieldId = 0) => {
    return <TableWrapper>
      <DataTable
        title={title}
        columns={columns}
        defaultSortFieldId={defaultSortFieldId}
        defaultSortAsc={true}
        data={data}
        //actions={<button className='action_button' onClick={() => downloadXLS(data, section)}>Descargar en Excel</button>}
        pagination
        striped
        highlightOnHover
        responsive
        paginationComponentOptions={paginationOptions}
        paginationPerPage={20}
        paginationRowsPerPageOptions={[20, 50, 100, 200]}
      />
    </TableWrapper>
  }

  return (<>
    <FilterContainer className="gero_sessions_filters_parent_container">
      <FilterItem className="gero_sessions_filter_container">
        <p>Desde:</p>
        <DatePicker
          selected={from}
          onChange={(date) => date ? setFrom(date) : setFrom(initialDate())}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={15}
          scrollableYearDropdown
          minDate={new Date("01-01-2020")}
          maxDate={to ? to : new Date()}
          dateFormat="dd/MM/yyyy"
          locale={es}
        />
      </FilterItem>
      <FilterItem className="gero_sessions_filter_container">
        <p>Hasta:</p>
        <DatePicker
          selected={to}
          onChange={(date) => date ? setTo(date) : setTo(new Date())}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          yearDropdownItemNumber={15}
          scrollableYearDropdown
          minDate={from ? from : new Date("01-01-2020")}
          maxDate={new Date()}
          dateFormat="dd/MM/yyyy"
          locale={es}
        />
      </FilterItem>
      <FilterItem className="gero_sessions_filter_container">
        <p>Chatbots:</p>
        <MultiSelect
          options={chatbotList}
          value={selectedChatbots}
          onChange={setSelectedChatbots}
          labelledBy="Select"
          overrideStrings={multiSelectTranslations}
        />
      </FilterItem>
      <FilterItem className="gero_sessions_filter_container">
        <p>Variable:</p>
        <InputFilter value={nombreVariable} onChange={(e) => setNombreVariable(e.target.value)} variant="outlined" />
        <p>Valor:</p>
        <InputFilter value={valorVariable} onChange={(e) => setValorVariable(e.target.value)} variant="outlined" />
      </FilterItem>
      <ButtonFilter className="action_button" disabled={isDisable} onClick={submitData}
              style={isDisable ? {opacity: 0.3, cursor: "not-allowed"} : {}}>
        Buscar
      </ButtonFilter>
    </FilterContainer>
    <div className="content_container">
      {!isLoading ?
        <div>
          <div className="summary_table">
            {createTable(i18n.es.gero_sessions.title, "sessions", sessionsColumns, sessions, 1)}
          </div>
        </div>
        : <img src={Spinner} alt={i18n.es.gero_sessions.loading} width={120} height={120} style={{marginTop: 150}}/>}
    </div>
    <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
      {currentSession && (<div style={{"display": "flex", "width": "100%"}}>
        <div style={{"display": "flex"}}>
          <pre>{currentSession.variables ? JSON.stringify(currentSession.variables, undefined, 2) : "{}"}</pre></div>
        <div style={{"display": "flex"}}>
          <div dangerouslySetInnerHTML={{ __html: "<iframe src='https://cell.hrbotfactory.com/?s="+currentSession.id+"' width='400' height='600' style='border: solid 1px darkgrey;border-radius: 8px;' />"}} />
        </div>
      </div>)}
    </Dialog>
  </>)
}




export default GeroSessions
