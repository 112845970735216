import React from 'react'
import { Show, SimpleShowLayout, TextField } from 'react-admin'

const ClientsShow = (props) => {
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="id" />
        <TextField source="client.id" />
        <TextField source="client.name" />
        <TextField source="user.id" />
        <TextField source="module_type.id" />
        <TextField source="sms_text" />
        <TextField source="successfully_at" />
        <TextField source="created_at" />
      </SimpleShowLayout>
    </Show>
  )
}

export default ClientsShow
