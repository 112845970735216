import React from 'react'
import { Datagrid, Filter, List, ReferenceField, TextField, TextInput, ReferenceInput, SelectInput } from 'react-admin'

const Filters = (props) => (
  <Filter {...props}>
    <TextInput source="id" />
    <ReferenceInput source="client.id" reference="clients" allowEmpty>
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput source="module_type.id" reference="modules" allowEmpty label="module">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const ReminderConfigsList = (props) => (
  <List {...props} exporter={false} filters={<Filters />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="description" />
      <TextField source="sendgrid_template_id" />
      <ReferenceField source="client.id" reference="clients" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="days" />
      <TextField source="order" />
    </Datagrid>
  </List>
)

export default ReminderConfigsList
