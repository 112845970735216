import React from 'react'
import {
    List,
    Datagrid,
    TextField,
    ReferenceField,
    ChipField,
    Filter,
    TextInput,
    CloneButton,
    FunctionField
} from 'react-admin'

const CandidaturesFilter = props => (
    <Filter {...props}>
        <TextInput source="id"/>
        <TextInput source="name"/>
        <TextInput source="remote_name"/>
        <TextInput source="client.name" label="client.name"/>
    </Filter>
)

const CandidaturesList = props => {
    return (
        <List {...props} exporter={false} filters={<CandidaturesFilter/>}>
            <Datagrid rowClick="show">
                <TextField source="id"/>
                <TextField source="name"/>
                <TextField source="remote_name"/>
                <ReferenceField source="client" reference="clients" allowEmpty>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField source="skill1" reference="skills">
                    <ChipField source="name"/>
                </ReferenceField>

                <ReferenceField source="skill2" reference="skills">
                    <ChipField source="name"/>
                </ReferenceField>

                <ReferenceField source="skill3" reference="skills">
                    <ChipField source="name"/>
                </ReferenceField>

                <ReferenceField source="skill4" reference="skills">
                    <ChipField source="name"/>
                </ReferenceField>
                <FunctionField render={record => {
                    return <CloneButton
                        className="button-clone"
                        // eslint-disable-next-line
                        basePath={props.basePath}
                        record={record}
                    />
                }}/>

            </Datagrid>
        </List>
    )
}

export default CandidaturesList
