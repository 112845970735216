import React from 'react'
import {
  BooleanInput,
  Create,
  email,
  FormDataConsumer,
  ImageField,
  ImageInput,
  minLength,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  AutocompleteInput
} from 'react-admin'

import {UserRoles} from '../common.js'

const UserCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" validate={[required()]}/>
        <TextInput source="surname" validate={[required()]}/>
        <TextInput source="email" validate={[required(), email()]}/>
        <NumberInput source="phone" validate={[required()]}/>
        <TextInput source="password" type="password" validate={[required(), minLength(8)]}/>
        <ReferenceInput source="client.id" reference="clients" allowEmpty label="Cliente"
                      sort={{field: 'name', order: 'ASC'}} perPage={1000}
                      filterToQuery={(searchText) => ({ name: searchText})}
        >
          <AutocompleteInput source="name"/>          
        </ReferenceInput>
        <ImageInput source="avatar" accept="image/*">
          <ImageField source="imageBase64" title="title" multiple={false}/>
        </ImageInput>

        <BooleanInput source="technical_selection_user.enabled"/>
        <FormDataConsumer>
          {// eslint-disable-next-line no-unused-vars
            ({ formData, ...rest }) => (
              formData.technical_selection_user &&
              formData.technical_selection_user.enabled &&
              <>
                <div className="child-items">
                  <SelectInput source="technical_selection_user.role" validate={[required()]} choices={UserRoles} />
                </div>
                <div className="child-items">
                  <TextInput fullWidth={true} multiline source="technical_selection_user.filters_default" label="filtersUserDefault"/>
                </div>
              </>
            )}
        </FormDataConsumer>
        <BooleanInput source="rrhh_user.enabled"/>

        <FormDataConsumer>
          {({ formData, ...rest }) => (
            formData.rrhh_user &&
            formData.rrhh_user.enabled &&
            <>
              <div className="child-items">
                <SelectInput source="rrhh_user.role" validate={[required()]} choices={[
                  { id: 'BASIC', name: 'Básico' },
                  { id: 'SUPERVISOR', name: 'Supervisor' },
                  { id: 'READ_ONLY', name: 'Solo lectura' },
                ]} />
              </div>
              <div className="child-items">
                <TextInput fullWidth={true} multiline source="rrhh_user.filters_default" label="filtersUserDefault"/>
              </div>
            </>
          )}
        </FormDataConsumer>

        <BooleanInput source="user_on_boarding.enabled"/>
        <FormDataConsumer>
          {// eslint-disable-next-line no-unused-vars
            ({ formData, ...rest }) => (
              formData.user_on_boarding &&
              formData.user_on_boarding.enabled &&
              <>
                <div className="child-items">
                  <SelectInput source="user_on_boarding.role" validate={[required()]} choices={UserRoles} />
                </div>
                <div className="child-items">
                  <TextInput fullWidth={true} multiline source="user_on_boarding.filters_default" label="filtersUserDefault"/>
                </div>
              </>
            )}
        </FormDataConsumer>

        <BooleanInput source="user_feedback.enabled"/>
        <FormDataConsumer>
          {// eslint-disable-next-line no-unused-vars
            ({ formData }) => (
              formData.user_feedback &&
              formData.user_feedback.enabled &&
              <>
                <div className="child-items">
                  <SelectInput source="user_feedback.role" validate={[required()]} choices={UserRoles} />
                </div>
                <div className="child-items">
                  <TextInput fullWidth={true} multiline source="user_feedback.filters_default" label="filtersUserDefault"/>
                </div>
              </>
            )}
        </FormDataConsumer>

        <BooleanInput source="user_exit_interview.enabled"/>
        <FormDataConsumer>
          {// eslint-disable-next-line no-unused-vars
            ({ formData }) => (
              formData.user_exit_interview &&
              formData.user_exit_interview.enabled &&
              <>
                <div className="child-items">
                  <SelectInput source="user_exit_interview.role" validate={[required()]} choices={UserRoles} />
                </div>
                <div className="child-items">
                  <TextInput fullWidth={true} multiline source="user_exit_interview.filters_default" label="filtersUserDefault"/>
                </div>
              </>
            )}
        </FormDataConsumer>

        <BooleanInput source="admin_user.enabled"/>

      </SimpleForm>
    </Create>
  )
}


export default UserCreate
