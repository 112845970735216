import React from 'react'
import { Edit, SimpleForm, TextInput, NumberInput, required } from 'react-admin'

const AnswersProfilesEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm redirect="show">
      <TextInput source="name" validate={[required()]} />
      <TextInput source="value" validate={[required()]} />
      <NumberInput source="score" validate={[required()]} />
    </SimpleForm>
  </Edit>
)

export default AnswersProfilesEdit
