import React from 'react'
import {Create, SimpleForm, TextInput, ReferenceInput, SelectInput, NumberInput, AutocompleteInput} from 'react-admin'

const ReportFieldsCreate = props => {
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <TextInput source="name" />
        <TextInput source="remote_name" />
        <ReferenceInput source="client.id" reference="clients" allowEmpty label="Cliente"
                    sort={{field: 'name', order: 'ASC'}} perPage={1000}
                    filterToQuery={(searchText) => ({ name: searchText})}
        >
          <AutocompleteInput source="name"/>
        </ReferenceInput>
        <ReferenceInput source="module_type.id" reference="modules" perPage={100} allowEmpty label="module">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="order" />
      </SimpleForm>
    </Create>
  )
}

export default ReportFieldsCreate
