import { api } from '../config'
import httpClient from '../providers/dataProvider/httpClient'

export const login = async ({ username, password }) => {
  const request = new Request(`${api.BASE_URL}/authentication_token`, {
    method: 'POST',
    body: JSON.stringify({ username, password }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
  const response = await fetch(request)
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText)
  }

  return response.json()
}

export const getAvatarUrl = ({ resource, slug }) => `${api.BASE_URL}/api/admin/${resource}/${slug}/avatar`

export const getAvatar = async ({ resource, slug }) =>
  new Promise((resolve, reject) => {
    httpClient(getAvatarUrl({ resource, slug }), {
      method: 'GET',
    })
      .then((response) => resolve(response.json))
      .catch(reject)
  })

export const getLogoUrl = ({ slug }) => `${api.BASE_URL}/api/admin/clients/${slug}/logo`

export const getLogo = async ({ slug }) =>
  new Promise((resolve, reject) => {
    httpClient(getLogoUrl({ slug }), {
      method: 'GET',
    })
      .then((response) => resolve(response.json))
      .catch(reject)
  })

export const importBlacklist = async ({ moduleId, slug, file }) =>
  httpClient(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENTRYPOINT}/module/${moduleId}/client/${slug}/backlist/csv_import`, {
    method: 'POST',
    body: JSON.stringify({ file }),
  })

export const removeUsersByClient = async (clientId, moduleId, fromDate, toDate, state) => {
  let states = ''
  for (let iterator = 0; iterator < state.length; iterator++) {
    states += '&state[]=' + state[iterator]
  }
  httpClient(`${api.BASE_URL}/api/admin/db/clear/client/${clientId}/module/${moduleId}?fromDate=${fromDate}&toDate=${toDate}${states}`, {
    method: 'DELETE',
  })
}

export const getClientsFormattedList = async () =>
  httpClient(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENTRYPOINT}/sms_reports/client_list`, {
    method: 'GET',
  })

export const getSmsReportsFetchData = async (from, to, clients = []) =>
  httpClient(`${process.env.REACT_APP_API_BASE_URL}${process.env.REACT_APP_API_ENTRYPOINT}/sms_reports/fetch_data`, {
    method: 'POST',
    body: JSON.stringify({ from, to, clients }),
  })


export const getGeroChatbots = async () =>
    httpClient(`${process.env.REACT_APP_GERO_API_BASE_URL}/stats/chatbots`, {
        method: 'GET',
    })


export const getGeroSessions = (date_from, date_to, chatbots=[], variables={}) =>
    httpClient(`${process.env.REACT_APP_GERO_API_BASE_URL}/stats/sessions`, {
        method: 'POST',
        body: JSON.stringify({ date_from, date_to, chatbots, variables }),
    })