import React from 'react'
import { Datagrid, Filter, List, TextField, TextInput, DateTimeInput } from 'react-admin'

const SmsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="client.id" />
    <TextInput source="client.name" />
    <DateTimeInput source="created_at.from" />
    <DateTimeInput source="created_at.to" />
  </Filter>
)

const SmsList = (props) => (
  <List {...props} exporter={false} filters={<SmsFilter />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="client.id" />
      <TextField source="client.name" />
      <TextField source="user.id" />
      <TextField source="module_type.id" />
      <TextField source="successfully_at" />
      <TextField source="created_at" />
    </Datagrid>
  </List>
)

export default SmsList
