import { api } from "../../../../config";
import httpClient from "../../../../providers/dataProvider/httpClient";

export function WhatsAppSenderTest() {
    const sendWhatsApp = async (whatsappSenderId, wabaKey, whatsappTemplate, whatsappTo, lang = 'es') => {
        const {status, headers, body, json} = await httpClient(`${api.BASE_URL}/api/admin/client/whatsapp-test`, {
            method: 'POST',
            body: JSON.stringify({
                senderId: whatsappSenderId,
                wabaToken: wabaKey,
                template: whatsappTemplate,
                to: whatsappTo,
                lang: lang
            })
        });

        if (status == 200 && json.sender_result == 200) {
            return true;
        }
        return false;
    }

    return {
        sendWhatsApp
    };
}

export async function WhatsAppSenderTestCallback(callback) {
    if (typeof callback != 'function') {
        alert('La llamada debe suministrar una función');
        return;
    }
    const { whatsappSenderId, wabaKey, whatsappTemplate, whatsappTo, lang } = callback();
    const { sendWhatsApp } = WhatsAppSenderTest();

    return await sendWhatsApp(whatsappSenderId, wabaKey, whatsappTemplate, whatsappTo, lang);
}