import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin'
import jwtDecode from 'jwt-decode'
import _ from 'lodash'
import * as apiConfig from '../../config/api'
import * as api from '../../api'

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    return login(params)
  } else if (type === AUTH_LOGOUT) {
    return logout(params)
  } else if (type === AUTH_ERROR) {
    return error(params)
  } else if (type === AUTH_CHECK) {
    return check(params)
  } else if (type === AUTH_GET_PERMISSIONS) {
    return getPermissions(params)
  }

  return Promise.resolve()
}

const login = async ({ username, password }) => {
  const { token } = await api.login({ username, password })
  localStorage.setItem('token', token)
  //localStorage.setItem('email', username)

  //const userInfo = await api.fetchUserInfo(token)
  //localStorage.setItem('userInfo', JSON.stringify(userInfo))

  return { token }
}

const logout = async () => {
  localStorage.removeItem('token')
}

const error = ({ status }) => {
  // if (status === undefined || status === 401 || status === 403) {
  //   localStorage.removeItem('token')
  //   localStorage.removeItem('email')
  //   return Promise.reject()
  // }
  return Promise.resolve()
}

export const storeToken = token => {
  const decoded = jwtDecode(token)
  localStorage.setItem('token', token)
  localStorage.setItem('email', decoded.username)
}

const check = () => (localStorage.getItem('token') ? Promise.resolve() : Promise.reject())

const getPermissions = () => {
  const token = localStorage.getItem('token')
  const decoded = jwtDecode(token)
  const permissions = _.get(decoded, 'roles')
  return permissions ? Promise.resolve(permissions) : Promise.reject()
}

export const getAuthToken = () => localStorage.getItem('token')

export const getUserInfo = () => JSON.parse(localStorage.getItem('userInfo'))

export const isAdminUser = permissions => {
  const isRoleAdmin = _.find(permissions, permission => permission === apiConfig.roles.ROLE_ADMIN)
  return !_.isNil(isRoleAdmin)
}
