import React from 'react'
import { Create, SimpleForm, TextInput, ReferenceInput, SelectInput } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const DefaultMessagesCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="title" />
      <RichTextInput toolbar={null} source="body" />
      <TextInput source="location" />
      <TextInput source="interviewer" />
      <ReferenceInput source="client" reference="clients" perPage={100}>
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput source="module_type.id" reference="modules" perPage={100} label="module">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export default DefaultMessagesCreate
