import React from 'react'
import {Datagrid, ReferenceArrayField, ReferenceField, Show, Tab, TabbedShowLayout, TextField} from 'react-admin'

const Questions = ({...props}) => {
  return (
    <Datagrid rowClick="show" {...props}>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="internal_name" />
    </Datagrid>
  )
}

const CandidaturesShow = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="summary">
        <TextField source="name"/>
        <TextField source="remote_name"/>

        <ReferenceField source="client.id" reference="clients">
          <TextField source="name" />
        </ReferenceField>
      </Tab>

      <Tab label="resources.processes.tabs.questions">
        <ReferenceArrayField source="questions" reference="questions" addLabel={false}>
          <Questions />
        </ReferenceArrayField>
      </Tab>

    </TabbedShowLayout>
  </Show>
)

export default CandidaturesShow
