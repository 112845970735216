import React from 'react'
import { Admin, Resource } from 'react-admin'
import { admin } from '../../../config'

import ClientsResource from '../../pages/clients'
import UsersResource from '../../pages/users'
import CandidaturesResource from '../../pages/candidatures'
import ModulesResource from '../../pages/modules'
import ProcessTypesResource from '../../pages/processTypes'
import SkillsResource from '../../pages/skills'
import ProfilesResource from '../../pages/profiles'
import QuestionsResource from '../../pages/questions'
import AnswersSkillsResource from '../../pages/answers_skills'
import FiltersResources from '../../pages/filters'
import FiltersBlockResource from '../../pages/filter_block';
import ReportFieldsResources from '../../pages/report_fields'
import AnswersProfilesResource from '../../pages/answers_profiles'
import MbtisTypesResource from '../../pages/mbtisTypes'
import AxisResource from '../../pages/axis'
import DefaultMessagesResource from '../../pages/default_messages'
import InboxesResource from '../../pages/inboxes'
import AvailabilityTag from '../../pages/availability_tags'
import NotInterestedTag from '../../pages/not_interested_tags'
import Blacklists from '../../pages/black_list'
import ProcessResource from '../../pages/processes'
import SuggestionFeedbackConfigResource from '../../pages/suggestion_feedback_configs'
import ExitInterviewConfigResource from '../../pages/exit_interview_configs'
import OnboardingConfigs from '../../pages/onboarding_configs'
import ReminderConfigs from '../../pages/reminder_configs'
import Sms from '../../pages/sms'
import Tags from '../../pages/tags'
import SmsReports from '../../pages/sms_reports'
import GeroSessions from '../../pages/gero_sessions'


const App = () => (
  <Admin {...admin}>
      
    {/*General */}
    <Resource name="clients" {...ClientsResource} />
    <Resource name="users" {...UsersResource} />

    {/*Module Talent Acquisition */}
    <Resource name="candidatures" {...CandidaturesResource} />
    <Resource name="skills" {...SkillsResource} />
    <Resource name="profiles" {...ProfilesResource} />
    <Resource name="answers_skills" {...AnswersSkillsResource} />
    <Resource name="answers_profile" {...AnswersProfilesResource} />
    <Resource name="types/mbtis" {...MbtisTypesResource} />
    <Resource name="axis" {...AxisResource} />
    <Resource name="availabilities" {...AvailabilityTag} />
    <Resource name="disinteresteds" {...NotInterestedTag} />

    {/*Module Employee Line */}
    <Resource name="processes" {...ProcessResource} />
    <Resource name="processtypes" {...ProcessTypesResource} />

    {/*Module Feedback */}
    <Resource name="suggestionfeedbackconfigs" {...SuggestionFeedbackConfigResource} />

    {/*Module ExitInterview */}
    <Resource name="exitinterviewconfigs" {...ExitInterviewConfigResource} />

    {/*MultiModule*/}
    <Resource name="modules" {...ModulesResource} />
    <Resource name="questions" {...QuestionsResource} />
    <Resource name="onboardingconfigs" {...OnboardingConfigs} />
    <Resource name="filters" {...FiltersResources} />
    <Resource name="filter_block" {...FiltersBlockResource} />
    <Resource name="reportfields" {...ReportFieldsResources} />
    <Resource name="inboxes" {...InboxesResource} />
    <Resource name="default_messages" {...DefaultMessagesResource} />
    <Resource name="blacklists" {...Blacklists} />
    <Resource name="reminderconfigs" {...ReminderConfigs} />
    <Resource name="sms" {...Sms} />
    <Resource name="sms_reports" {...SmsReports} />
    <Resource name="tags" {...Tags} />
    <Resource name="gero_sessions" {...GeroSessions} />

  </Admin>
)

export default App
