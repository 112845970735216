import styled from 'styled-components'

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
`

export const Modal = styled.div`
  background: #ffffff;
  width: 400px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  top: 200px;
  filter: drop-shadow(0px 4px 13px rgba(9, 16, 39, 0.15));
  border-radius: 6px;
  z-index: 99999;
`

export const DatesHolder = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  input {
    width: 100%;
    padding: 10px;
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
`

export const ConfirmText = styled.div`
  color: red;
`

export const ButtonsHolder = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  a {
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
`

export const Button = styled.a`
  display: inline-block;
  background-color: transparent;
  padding: 0.35em 1.2em;
  border: 0.1em solid #000000;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  color: #000000;
  text-align: center;
  transition: all 0.2s;
  &:hover {
    color: red;
    border: 1px solid red;
  }
`
