import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import inflection from 'inflection'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Collapse from '@material-ui/core/Collapse'
import classnames from 'classnames'
import { getResources, translate } from 'ra-core'
import DefaultIcon from '@material-ui/icons/ViewList'
import { DashboardMenuItem, Responsive } from 'react-admin'
import _ from 'lodash'
import { MenuItemLink } from '../../atoms'

const styles = theme => ({
  main: {
    padding: '20px 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    background: theme.palette.primary.main,
    height: 'calc(100vh - 105px)',
    minHeight: 500
  }
})

const translatedResourceName = (resource, translate) =>
  translate(`resources.${resource.name}.name`, {
    smart_count: 2,
    _:
      resource.options && resource.options.label
        ? translate(resource.options.label, {
            smart_count: 2,
            _: resource.options.label
          })
        : inflection.humanize(inflection.pluralize(resource.name))
  })

const Menu = ({ classes, className, dense, hasDashboard, onMenuClick, open, pathname, resources, translate, logout, ...rest }) => {
  const resourcesList = _.reduce(
    resources,
    (result, resource) => {
      if (resource.hasList) {
        const submenuKey = _.get(resource, 'options.submenu', false)
        const submenuIcon = _.get(resource, 'options.submenuIcon', false)
        if (submenuKey) {
          if (!result[submenuKey]) {
            result[submenuKey] = { name: submenuKey, isGroup: true, resources: {}, hasList: true, submenuIcon }
          }
          result[submenuKey].resources[resource.name] = resource
        } else {
          result[resource.name] = resource
        }
      }
      return result
    },
    {}
  )

  const collapsedKeysItems = _.filter(resourcesList, 'isGroup')
  const collapsedKeys = _.chain(collapsedKeysItems)
    .keyBy('name')
    .mapValues('isGroup')
    .value()
  const [collapsed, setCollapsed] = React.useState(collapsedKeys)

  const handleClick = collapsedKey => {
    const newCollapsed = { ...collapsed, [collapsedKey]: !collapsed[collapsedKey] }
    setCollapsed(newCollapsed)
  }

  const _renderSubresources = subresources => {
    return _.map(subresources, subResource => (
      <MenuItemLink
        key={subResource.name}
        to={`/${subResource.name}`}
        primaryText={translatedResourceName(subResource, translate)}
        leftIcon={subResource.icon ? <subResource.icon /> : <DefaultIcon />}
        onClick={onMenuClick}
        dense={dense}
      />
    ))
  }

  return (
    <div className={classnames(classes.main, className)} {...rest}>
      {hasDashboard && <DashboardMenuItem onClick={onMenuClick} />}
      {_.map(resourcesList, resource => {
        if (resource.isGroup && open) {
          return (
            <div key={resource.name}>
              <MenuItemLink
                to={pathname}
                primaryText={translatedResourceName(resource, translate)}
                leftIcon={resource.submenuIcon ? <resource.submenuIcon /> : <DefaultIcon />}
                rightIcon={collapsed[resource.name] ? <ExpandLess /> : <ExpandMore />}
                onClick={() => handleClick(resource.name)}
                dense={dense}
              />
              <Collapse in={collapsed[resource.name]} timeout="auto" unmountOnExit>
                <List component="div" style={{ paddingLeft: 30 }}>
                  {_renderSubresources(resource.resources)}
                </List>
              </Collapse>
            </div>
          )
        } else if (resource.isGroup && !open) {
          return _renderSubresources(resource.resources)
        } else {
          return (
            <MenuItemLink
              key={resource.name}
              to={`/${resource.name}`}
              primaryText={translatedResourceName(resource, translate)}
              leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
              onClick={onMenuClick}
              dense={dense}
            />
          )
        }
      })}
      <Responsive xsmall={logout} medium={null} />
    </div>
  )
}

Menu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
  onMenuClick: PropTypes.func,
  open: PropTypes.bool,
  pathname: PropTypes.string,
  resources: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired
}

Menu.defaultProps = {
  onMenuClick: () => null
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
  resources: getResources(state),
  pathname: state.router.location.pathname // used to force redraw on navigation
})

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
    {}, // Avoid connect passing dispatch in props,
    null,
    {
      areStatePropsEqual: (prev, next) =>
        prev.resources.every(
          (value, index) => value === next.resources[index] // shallow compare resources
        ) &&
        prev.pathname === next.pathname &&
        prev.open === next.open
    }
  ),
  withStyles(styles)
)

export default enhance(Menu)
