import React from 'react'
import { ReferenceField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin'

const ReminderConfigsShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" />
          <TextField source="description" />
          <ReferenceField source="client.id" reference="clients" allowEmpty>
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField source="module_type.id" reference="modules" allowEmpty label="module">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="sendgrid_template_id" />
          <TextField source="days" />
          <TextField source="order" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default ReminderConfigsShow
