import React from 'react'
import { BooleanField, EmailField, Show, Tab, TabbedShowLayout, TextField } from 'react-admin'
import { ColorField } from 'react-admin-color-input'
import { LogoField } from '../../../atoms'
import { MODULE_IDS } from '../../../../config/api'

const ClientsShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="summary">
          <TextField source="id" />
          <TextField source="name" />
          <EmailField source="email" />
          <TextField source="email_from" />
          <BooleanField source="sms_available" />
          <BooleanField source="whatsapp_available" />
          <TextField source="sms_prefix" />
          <TextField source="sendgrid_token" />
          <TextField source="whatsapp_voucher" />
          <BooleanField source={'module_' + MODULE_IDS.ID_TALENT_ACQUISITION + '.enabled'} />
          <BooleanField source={'module_' + MODULE_IDS.ID_EMPLOYEE_LINE + '.enabled'} />
          <BooleanField source={'module_' + MODULE_IDS.ID_ON_BOARDING + '.enabled'} />
          <BooleanField source={'module_' + MODULE_IDS.ID_FEEDBACK + '.enabled'} />
          <BooleanField source={'module_' + MODULE_IDS.ID_EXIT_INTERVIEW + '.enabled'} />
        </Tab>

        <Tab label="resources.clients.tabs.colors">
          <ColorField source="text_color_principal" />
          <ColorField source="text_color_secondary" />
          <ColorField source="background_color" />
          <LogoField source="logo" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default ClientsShow
