import React from 'react'
import { Datagrid, Filter, List, ReferenceField, TextField, TextInput, ReferenceInput, SelectInput, AutocompleteInput } from 'react-admin'
import {ColorField} from "react-admin-color-input";

const Filters = (props) => (
  <Filter {...props}>
    <TextInput source="id" />
    <ReferenceInput source="client.id" reference="clients" allowEmpty label="Cliente"
                    sort={{field: 'name', order: 'ASC'}} perPage={1000}
                    filterToQuery={(searchText) => ({ name: searchText})}
    >
      <AutocompleteInput source="name"/>
    </ReferenceInput>
    <ReferenceInput source="module_type.id" reference="modules" allowEmpty label="module">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const ReminderConfigsList = (props) => (
  <List {...props} exporter={false} filters={<Filters />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField source="client.id" reference="clients" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="module_type.id" reference="modules" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
      <TextField source="name" />
      <ColorField source="color" />
      <TextField source="order" />
    </Datagrid>
  </List>
)

export default ReminderConfigsList
