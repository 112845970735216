import spanishMessages from 'aor-language-spanish'

export default {
  ...spanishMessages,
  'Expired JWT Token': 'La sesión ha caducado',
  'Invalid JWT Token': 'La sesión ha caducado',
  HttpError: 'Error al conectar con el servidor',
  filterProfiles: 'Filtrar perfiles',
  searchProfiles: 'Buscar perfiles',
  noClientsSelected: 'Selecciona un cliente en el selecctor para ver sus cadidaturas',
  selectClient: 'Selecciona un cliente ...',
  summary: 'Información',
  module: 'Módulo',
  processType: 'Tipo de proceso',
  'client.id': 'Cliente',
  'client.name': 'Cliente',
  questions: 'Preguntas',
  filtersUserDefault: 'Filtros por defecto para el usuario',
  resources: {
    clients: {
      name: 'Clientes',
      fields: {
        name: 'Nombre',
        background_color: 'Color de fondo',
        text_color_principal: 'Color del texto principal',
        text_color_secondary: 'Color del texto secundario',
        candidatures: 'Candidaturas',
        stats_filters_available: 'Filtros habilitados para estadísticas',
        module_talent_acquisition: {
          enabled: 'Módulo de Candidaturas',
          skill_weight: 'Peso Skill',
          profile_weight: 'Peso Profile',
          weight_skill1: 'Peso Skill 1',
          weight_skill2: 'Peso Skill 2',
          weight_skill3: 'Peso Skill 3',
          inbox_fields: 'Columnas inbox',
          minimum_percentage_suitable: 'Porcentaje mínimo',
          token_sendgrid_email_bot_starter_template: 'Sengrid Tpl. Id email para bot-starter',
          token_sendgrid_email_bot_starter_repush_template: 'Sengrid Tpl. Id email para bot-starter-repush',
          token_sendgrid_email_bot_starter_recurrent_template: 'Sengrid Tpl. Id email para bot-starter-recurrent',
          token_sendgrid_email_bot_feedback_template: 'Sengrid Tpl. Id email para bot-feedback',
          token_sendgrid_chat_template: 'Sendgrid Tpl. Id chat',
          token_sendgrid_citation_template: 'Sengrid Tpl. Id citation',
          token_sendgrid_email_notice_template: 'Sendgrid Tpl. Aviso',
          talent_clue_integrated: 'Talent Clue',
          sms_start_bot_template: 'SMS Template para empezar el bot',
          sms_repush_bot_template: 'SMS Template para repush bot',
          sms_feedback_bot_template: 'SMS Template para Feedack bot',
          sms_start_bot_template_helper: "Reemplazos disponibles {{url_notification}} > URL bot",
          sms_bot_starter_recurrent_template: 'SMS Template para bot-starter-recurrent',
          bot_url: "Url bot (Wannabot)",
          schedules_available_to_notify_bot_starter: "Horario para poder notificar",
          schedules_available_to_notify_bot_starter_helper: "https://crontab.guru/#*_10-13,17-20_*_*_1,2,3,4,5",
          update_candidature_in_days: 'Actualizar candidatos en X días',
          update_candidature_bot_url: 'Url bot actualizar candidatos',
          whatsapp_sender_id: 'Id del remitente de Whatsapp',
          waba_key: 'Clave Waba',
          whatsapp_template: 'Plantilla de whatsapp para bot-starter',
          whatsapp_test_phone: 'Teléfono para pruebas'
        },
        module_employee_line: {
          enabled: 'Módulo Linea de Empleado',
          inbox_fields: 'Columnas inbox',
          sendgrid_process_new_template: 'Sendgrid Tpl. Id nuevo proceso',
          sendgrid_process_status_changed_template: 'Sendgrid Tpl. estado cambiado',
        },
        module_on_board: {
          enabled: 'Módulo Onboarding',
        },
        module_feedback: {
          enabled: 'Módulo Feedback',
          sendgrid_feedback_new_template: 'Sendgrid tpl New chatbot'
        },
        module_exit_interview: {
          enabled: 'Módulo ExitInterview',
          sendgrid_new_exit_interview_template: 'Sendgrid tpl New chatbot'
        },
        email_from: 'Email de',
        logo: 'Logo',
        currentLogo: 'Logo actual',
        sms_available: 'SMS activado',
        sms_prefix: 'Prefijo SMS',
      },
      tabs: {
        colors: 'Colores',
        candidatures: 'Candidaturas',
        modules: 'Módulos',
        inboxes: 'Inboxes',
        default_messages: 'Mensajes por defecto',
        technical_selections: 'Técnicos de selección',
        filters: 'Filtros',
      },
    },
    technical_selections: {
      name: 'Técnicos de selección',
      fields: {
        name: 'Nombre',
        surname: 'Apellido',
        'client.id': 'Cliente',
        phone: 'Teléfono',
        email: 'Email',
        client: 'Cliente',
        password: 'Contraseña',
        avatar: 'Avatar',
        currentAvatar: 'Avatar actual',
      },
    },
    blacklists: {
      name: 'Blacklist',
      fields: {
        'client.id': 'Id',
        'client.name': 'Nombre',
      },
      modal: {
        document: 'Documento',
        client: 'Cliente',
      },
      notifications: {
        importSuccess: 'Importado con éxito',
      },
      import: 'Importar',
    },
    onboardingconfigs: {
      fields: {
        questions: 'Preguntas',
        cliente: 'Cliente',
      },
    },
    candidatures: {
      name: 'Candidaturas',
      fields: {
        name: 'Nombre',
        skill_weight: 'Peso Skill',
        profile_weight: 'Peso Profile',
        remote_name: 'Nombre Wannabot',
        weight_skill1: 'Peso Skill 1',
        weight_skill2: 'Peso Skill 2',
        weight_skill3: 'Peso Skill 3',
        questions: 'Preguntas',
        'skill1.id': 'Skill1',
        'skill2.id': 'Skill2',
        'skill3.id': 'Skill3',
        'client.id': 'Cliente',
        client: 'Cliente',
        minimum_percentage_suitable_overwrite: 'Porcentaje mín (Sobrees. cliente)',
        skill_weight_overwrite: 'Peso Skill (Sobreescribir cliente)',
        profile_weight_overwrite: 'Peso Profile (Sobreescribir cliente)',
      },
      tabs: {
        questions: 'Preguntas',
      },
    },
    inboxes: {
      name: 'Columnas inbox',
      fields: {
        name: 'Nombre',
        remote_name: 'Nombre Wannabot',
      },
    },
    modules: {
      name: 'Módulos',
      fields: {
        name: 'Nombre',
      },
    },
    skills: {
      name: 'Skills',
      fields: {
        name: 'Nombre',
      },
    },
    'default/messages': {
      name: 'Mensajes por defecto',
      fields: {
        title: 'Título',
        body: 'Cuerpo',
        client: 'Cliente',
      },
    },
    profiles: {
      name: 'Perfiles',
      fields: {
        name: 'Nombre',
      },
    },
    questions: {
      name: 'Preguntas',
      fields: {
        name: 'Nombre',
        profile: 'Perfil',
        variable_field: 'Campo variable',
        is_editable_by_client: 'Editable por cliente',
        remote_name: 'Nombre Wannabot',
        internal_name: 'Nombre interno',
        questions: 'Preguntas',
        answers: 'Respuestas',
        weight: 'Peso',
        'profile.id': 'Perfil',
      },
      tabs: {
        answers: 'Respuestas',
      },
    },
    answers_skills: {
      name: 'Respuestas Skill',
      fields: {
        name: 'Nombre',
        value: 'Valor respuesta',
        mbtiType_selected: 'Puntúa a:',
        mbti_value: 'Puntúa a:',
        'mbti_value.id': 'Puntúa a:',
        question: 'Preguntas',
        axis: 'Eje',
        'axis.id': 'Eje',
        punctuate: 'Puntúa a skill',
        'skill.id': 'Skill',
        mbtis: 'mbtis',
        axis1: 'axis1',
        axis2: 'axis2',
        axis3: 'axis3',
        axis4: 'axis4',
      },
      tabs: {
        mbtis: 'Mbti',
      },
    },
    answers_profile: {
      name: 'Respuestas Profile',
      fields: {
        value: 'Valor',
        score: 'Puntuación',
        question: 'Pregunta',
        weight: 'Peso',
        name: 'Nombre',
      },
    },
    axis: {
      name: 'Ejes',
      fields: {
        name: 'Nombre',
        values: 'Valores',
      },
      tabs: {
        values: 'Valores',
      },
    },
    'types/mbtis': {
      name: 'Tipos Mbtis',
      fields: {
        value: 'Valor',
        'axis.id': 'Ejes',
        axis: 'Ejes',
      },
    },
    availabilities: {
      name: 'Disponibilidad',
      fields: {
        label: 'Etiqueta',
        'client.id': 'Cliente',
        position: 'Posición',
        client: 'Cliente',
      },
    },
    reportfields: {
      name: 'Columnas reportes',
      fields: {
        name: 'Nombre',
        remote_name: 'Clave',
        type: 'Tipo',
        'client.id': 'Cliente',
      },
    },
    disinteresteds: {
      name: 'No Interesado',
      fields: {
        label: 'Etiqueta',
        'client.id': 'Cliente',
        position: 'Posición',
        client: 'Cliente',
      },
    },
    default_messages: {
      name: 'Mensajes por defecto',
      fields: {
        title: 'Título',
        body: 'Contenido',
        client: 'Cliente',
      },
    },
    filters: {
      name: 'Filtros',
      fields: {
        name: 'Nombre',
        remote_name: 'Nombre remoto',
        'moduleType.id': 'Modulo',
        'client.id': 'Cliente',
      },
    },
    processes: {
      name: 'Proceso',
      fields: {
        remote_name: 'Nombre Wannabot',
        prefix: 'Prefijo',
        questions: 'Preguntas',
        'type.id': 'Tipo de proceso',
      },
      tabs: {
        questions: 'Preguntas',
      },
    },
    users: {
      fields: {
        technical_selection_user: {
          enabled: 'Adquisición de talento',
          supervisor: 'Supervisor',
        },
        rrhh_user: {
          enabled: 'Linea de empleado',
          supervisor: 'Supervisor',
        },
        'admin_user.enabled': 'Admin',
        'client.id': 'Cliente',
      },
    },
    reminderconfigs: {
      name: 'Recordatorios',
      fields: {
        order: 'Orden',
        days: 'Días',
        sendgrid_template_id: 'Plantilla de Sendgrid',
        'client.id': 'Cliente',
      },
    },
    sms: {
      name: 'Sms',
      fields: {
        client: {
          id: 'Cliente',
          name: 'Nombre',
        },
        user: {
          id: 'Usuario',
        },
        module_type: {
          id: 'Módulo',
        },
        successfully_at: 'Fecha',
        created_at: 'Creado',
        'created_at.from': 'Desde',
        'created_at.to': 'Hasta',
      },
    },
  },
  sms_reports: {
    id: "Id",
    client: "Cliente",
    client_id: "Id Cliente",
    candidature_config: "Candidatura",
    unique_candidates: "Candidatos inscritos únicos",
    candidates_applied_email: "Email enviados",
    sent_email: "Email % enviados /inscritos",
    loaded_email: "Email abiertos",
    opened_email: "Email % abiertos /enviados",
    candidates_applied_sms: "SMS enviados",
    sent_sms: "SMS % enviados /inscritos",
    loaded_sms: "SMS abiertos",
    opened_sms: "SMS % abiertos /enviados",
    candidates_applied_whatsapp: "Whatsapp enviados",
    sent_whatsapp: "Whatsapp % enviados /inscritos",
    loaded_whatsapp: "Whatsapp abiertos",
    opened_whatsapp: "Whatsapp % abiertos /enviados",
    loaded: "Abiertos únicos",
    bots_done: "Bots realizados",
    opened_percentage: "Total % realizados /abiertos únicos",
    applied_percentage: "Total % realizados /inscritos",
    rowsPerPageText: 'Registros por pág:',
    rangeSeparatorText: 'de',
    selectAllRowsItemText: 'Todo',
    export: 'Exportar',
    loading: "Cargando...",
    title: {
      summary: "Resumen conversión",
      email: "Email enviados (sin repush)",
      sms: "Sms enviados (sin repush)",
      whatsapp: "Whatsapp enviados (sin repush)",
    },
  },
  gero_sessions: {
    title: "Sesiones Gero",
    loading: "Cargando sesiones."
  },
  whatsapp: {
    test: {
      success: "La prueba de envío de WhatsApp ha funcionado",
      error: "La prueba de envío de WhatsApp ha fallado",
    }
  }
}
